import { Box, Slider } from "@mui/material";

interface Props {
  color?: string;
  value?: number;
  onChange?: (e: Event) => void;
  maxValue?: number;
}

const StyledSLider = ({ color, value, onChange, maxValue }: Props) => {
  return (
    <Box className="StyledSlider">
      <Slider
        aria-label="Always visible"
        value={value}
        onChange={onChange}
        defaultValue={0}
        step={5}
        max={maxValue}
        sx={{ color }}
      />
    </Box>
  );
};

export default StyledSLider;
