import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import StyledPaper from "../../atoms/StyledPaper/StyledPaper";
import StyledSLider from "../../atoms/StyledSlider/StyledSlider";
import Text from "../../atoms/Text/Text";
import Title from "../../atoms/Title/Title";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";

interface Props {
  title: string;
  goal: number;
  setGoal: Dispatch<SetStateAction<number>>;
  isLoading?: boolean;
}

const DailyGoal = ({ title, goal, setGoal, isLoading }: Props) => {
  const { t } = useTranslation();

  const colorHandler = (value: number): string => {
    let color = "#E83C38";

    if (value > 59 && value < 181) {
      color = "#87C215";
    }

    return color;
  };

  if (isLoading) return <SpinnerLoader />;

  return (
    <StyledPaper>
      <Box className="DailyGoal">
        {!isLoading && (
          <Title style={{ marginBottom: 0 }} variant="h3">
            {title}
          </Title>
        )}
        <Box sx={{ display: "flex" }}>
          <Text>{`${t("activityTracking:MINUTE", {
            count: goal,
          })} / ${t("activityTracking:DAYS")} —\xa0`}</Text>
          <Text
            component="span"
            style={{ color: colorHandler(goal), paddingLeft: 0 }}
          >
            {t(
              colorHandler(goal) === "#E83C38"
                ? "activityTracking:DAILY_GOAL.NOT_RECOMMENDED"
                : "activityTracking:DAILY_GOAL.RECOMMENDED"
            )}
          </Text>
        </Box>
        <StyledSLider
          value={goal}
          onChange={(e) =>
            setGoal(parseInt((e.target as HTMLInputElement).value))
          }
          color={colorHandler(goal)}
          maxValue={240}
        />
      </Box>
    </StyledPaper>
  );
};

export default DailyGoal;
