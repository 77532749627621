export const DE = {
  AactivityOnboarding: {
    SUMMARY: {
      ACTIVITY_TEXT: 'Häufigkeit des Auslaufs'
    }
  },
  HipayPayments: {
    CARDNUMBER: 'Kartennummer',
    EXPIRYDATE: 'Ablaufdatum',
    FULLNAME: 'Vollständiger Name',
    GOCARDLESS_LINK: 'Durch Bankeinzug zahlen'
  },
  Slider: {
    GPS_INTERVAL: 'GPS-Intervall',
    GPS_POSITION_REPORT_ENABLED: 'GPS-Positionsübertragung aktiviert',
    TRACKER_INTERVAL_TEXT_WARNING: 'Wenn Sie die automatische Übertragung von GPS-Positionen ausschalten, erhöhen Sie die Lebensdauer des Akkus. Sie können zwar manuell einen neuen Standort abfragen, aber die Sicherheitszonen und der Verlauf werden nicht mehr angezeigt.'
  },
  TypeSwitch: {
    ANIMAL_SUBTITLE: 'Wählen Sie das Tier, für das Sie den Tracker gekauft haben:',
    CAT_TYPE: 'Katzen-Tracker',
    DOG_TYPE: 'Hunde-Tracker',
    FAMILY_SUBTITLE: 'Für wen haben Sie den Tracker gekauft?',
    KID_TYPE: 'Ein Kind',
    SILVER_TYPE: 'Ein Senior',
    TITLE: 'Für wen ist der Tracker?'
  },
  account: {
    LANGUAGE: {
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Es ist ein Problem aufgetreten.',
        UPDATE_SUCCESS: 'Sprache erfolgreich aktualisiert'
      }
    },
    NAV: {
      LANGUAGE: 'Sprache',
      PASSWORD: 'Passwort',
      PAYMENTS: 'Rechnungsstellung',
      USER_PREFERENCES: 'Voreinstellungen',
      USER_PROFILE: 'Benutzerprofil'
    },
    PASSWORD: {
      HEADLINE: 'Passwortänderung',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Es ist ein Problem aufgetreten.',
        UPDATE_ERROR_WRONG_PASSWORD: 'Sie haben ein falsches altes Passwort eingegeben.',
        UPDATE_SUCCESS: 'Das Passwort wurde geändert.'
      }
    },
    PAYMENTS: {
      HISTORY_HEADLINE: 'Zahlungsverlauf',
      MAIN_HEADLINE: 'Rechnungsstellung'
    },
    USER_PREFERENCES: {
      EMAIL_PREFERENCES_HEADLINE: 'E-Mail-Voreinstellungen',
      EMAIL_PREFERENCES_USER_INFO: 'Die Einstellungen werden auf die E-Mail-Adresse angewendet {{user_email}}',
      MAIN_HEADLINE: 'Voreinstellungen',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Beim Speichern der Voreinstellungen ist ein Problem aufgetreten.',
        UPDATE_SUCCESS: 'Voreinstellungen wurden erfolgreich aktualisiert.'
      },
      METRIC_PREFERENCES_DESCRIPTION: 'Wählen Sie die für Ihren Tracker verwendete Entfernungseinheit.',
      METRIC_PREFERENCES_HEADLINE: 'Präferenz für das metrische System :'
    },
    USER_PROFILE: {
      BASIC_INFO_HEADLINE: 'Basisinformationen',
      BILLING_HEADLINE: 'Rechnungsadresse',
      MAIN_HEADLINE: 'Benutzerprofil',
      MARKETING_AGREEMENT_TEXT: 'Ich bin damit einverstanden, Angebote und Werbeaktionen von Weenect-Partnern zu erhalten.',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Es ist ein Problem aufgetreten.',
        UPDATE_SUCCESS: 'Daten erfolgreich aktualisiert'
      },
      METRIC_KM: 'Kilometer',
      METRIC_MILES: 'Meilen',
      PREFERRED_METRIC: 'Zu verwendende Maβeinheit:'
    }
  },
  activity: {
    AVERAGE_SPEED: 'Durchschnittsgeschwindigkeit',
    CONTROLS: {
      PAUSE_PLAYING_TITLE: 'Verlaufs Video anhalten',
      RESUME_PLAYING_TITLE: 'Verlaufs Video erneut abspielen',
      START_PLAYING: 'Starten',
      STOP_PLAYING: 'Stopp',
      STOP_PLAYING_TITLE: 'Verlaufs Video anhalten',
      TOGGLE_HEATMAP: 'Thermokarte',
      TOGGLE_HEATMAP_TITLE: 'Thermokarte anzeigen',
      TOGGLE_PATH: 'Standortverlauf',
      TOGGLE_PATH_TITLE: 'Standortverlauf anzeigen'
    },
    DETAIL_TITLE: 'Aktivitätendetails',
    EMPTY_LIST: 'Keine Benachrichtigungen vorhanden. Ändern Sie den ausgewählten Zeitraum oder überprüfen Sie, dass der Tracker richtig eingeschaltet ist.',
    EVENTS: {
      ALERT: 'Seitentaste betätigt',
      BATTERY: 'Akku schwach – {{battery}}% verbleiben',
      CALL_END: 'SOS-Anruf beendet',
      CALL_REQ: 'SOS-Anruf angefordert',
      ENTERS_GEOFENCE: 'Eintritt in die Zone',
      EXIT_GEOFENCE: 'Verlassen der Zone',
      FLASH_REQ: 'Blitz gefragt',
      FREQ_CHANGE: 'Das Zeitintervall wurde geändert.',
      RING: 'Klingeln durchgeführt',
      RING_REQ: 'Klingelanfrage gesendet',
      SOS: 'SOS-Alarm',
      TRACKER_OFF: 'Tracker ausgeschaltet',
      TRACKER_ON: 'Tracker eingeschaltet',
      VIBRATE: 'Vibration durchgeführt',
      VIBRATE_REQ: 'Vibrationsanfrage gesendet'
    },
    FEEDBACKTEXT: {
      FIRST: '',
      SECOND: ''
    },
    HISTORY_TITLE: 'Benachrichtigungsverlauf',
    LAST_TIME_PERIOD: 'Letzte (...) anzeigen',
    LOADING: 'Wird geladen ...',
    MAXIMUM_SPEED: 'Max. Geschwindigkeit',
    OBJECTIVE: {
      CONTENT: 'Setzen Sie sich ein Ziel für die intensive Aktivität, das Sie täglich erreichen wollen.',
      DAILY_REACHED: '{{name}} hat ihr Tagesziel erreicht!',
      HELPTEXT: 'Bei der Messung des Tagesziels wird nur die intensive Aktivität berücksichtigt. Zum Beispiel ein Lauf oder ein aktives Spiel.',
      RECOMENDED_VALUE: 'Empfohlenes Ziel: 1 Stunde',
      SUBTEXT: 'Unser Algorithmus zeichnet jede Bewegung Ihres Trackers auf und klassifiziert die Ergebnisse dann nach Intensitätsgrad. Auf diese Weise können wir feststellen, ob es sich um eine intensive, moderate oder geringe Aktivität handelt.',
      TITLE: 'Tägliches Ziel'
    },
    ONBOARDING: {
      FIRST_SUBTEXT: 'Mit diesem Tool können Sie die täglichen Aktivitäten Ihres Vierbeiners verfolgen.',
      SECOND_SUBTEXT: 'Wie lange hat er geschlafen? Wie viele Schritte hat er heute gemacht?',
      START: 'Starten',
      THIRD_SUBTEXT: 'Sie können sich sogar ein Ziel für die Rasse Ihres Haustiers setzen, damit es die tägliche Aktivität bekommt, die es braucht.',
      TITLE: 'Willkommen bei der Weenect-Aktivitätsüberwachung!'
    },
    PROGRESSBAR: {
      TITLE: 'Tägliches Ziel'
    },
    SELECT_DAY: 'Einen Tag auswählen',
    SELECT_TIME: 'Benutzen Sie ihre Maus, um den Zeitraum auszuwählen, der angezeigt werden soll',
    SUMMARY: {
      ACTIVITY: 'Intensiv',
      AWAKENING: 'Moderat',
      DATEINTERVAL: '',
      DAY_INDEX: 't',
      DISTRIBUTIONTEXT: 'Übersicht',
      HOUR_INDEX: 's',
      LASTWEEK: '',
      MENU_TITLE: 'Tätigkeitsbericht',
      MIN_INDEX: 'min',
      MODIFY_PERIOD: 'Zeitraum ändern',
      PREVIOUS_DAY: '',
      PREVIOUS_MONTH: '',
      PREVIOUS_WEEK: '',
      REGISTERED_ACTIVITY: 'Schritte',
      REPORT: 'Bericht anzeigen ',
      REST: 'Gering',
      SUBTEXT: 'Noch {{time}} intensive Aktivität, bevor {{name}}s Ziel erreicht ist.',
      TEXT_OBJECTIVE_COMPLETED: '{{name}} hat ihr Tagesziel erreicht!',
      TEXT_OBJECTIVE_COMPLETED_TREAT: 'Herzlichen Glückwunsch, {{name}} hat ihr Ziel erreicht! Vielleicht verdient sie ein kleines Leckerli?',
      TEXT_OBJECTIVE_NONE: '{{name}} hat noch keine Daten für diesen Zeitraum.',
      TEXT_OBJECTIVE_NONE_VERIFY_TRACKER: '{{name}} hat noch keine Bewegungen aufgezeichnet. Vergewissern Sie sich, dass Ihr Tracker eingeschaltet und der Akku geladen ist!',
      TITTLE: '{{name}} Fortschritte',
      TODAY: 'Heute,',
      TOTAL: 'Gesamt',
      YESTERDAY: 'gestern'
    },
    TIME_ELAPSED: 'Dauer',
    TOTAL_DISTANCE: 'Gesamtentfernung'
  },
  activityGeneral: {
    BEHAVIOR: 'Aktivität',
    STANDARD_MORPHOLOGY: 'Morphologie ',
    STERILIZED: 'Kastriert?'
  },
  activityOnboarding: {
    COMMON: {
      STEP: 'Schritt',
      TRACKER_PROFIL: 'Tracker-Profil'
    },
    MONTH: {
      APRIL: 'April',
      AUGUST: 'August',
      DECEMBER: 'Dezember',
      FEBRUARY: 'Februar',
      JANUARY: 'Januar',
      JULY: 'Juli',
      JUNE: 'Juni',
      MARCH: 'März',
      MAY: 'Mai',
      NOVEMBER: 'November',
      OCTOBER: 'Oktober',
      SEPTEMBER: 'September'
    },
    PET_ACTIVITY: {
      FIRST_BUTTON: 'Inaktiv (minimale Aktivität)',
      FIRST_BUTTON_CAT: 'Ausnahmsweise (Urlaub, unvorhergesehene Ereignisse...)',
      FIRST_BUTTON_DOG: 'Nur für ihre Bedürfnisse',
      FOURTH_BUTTON: 'Sehr aktiv (zwei bis drei Spaziergänge pro Tag)',
      FOURTH_BUTTON_CAT: 'Regelmäßig, sowohl tagsüber als auch nachts',
      FOURTH_BUTTON_DOG: '2 bis 3 Spaziergänge/Tag',
      SECOND_BUTTON: 'Normal (zwei bis drei Spaziergänge pro Woche)',
      SECOND_BUTTON_CAT: 'Gelegentlich, auf eigenen Wunsch\n',
      SECOND_BUTTON_DOG: '2 bis 3 Spaziergänge pro Woche',
      SELECT_ACTIVITY: 'Wie oft geht es nach draußen?',
      THIRD_BUTTON: 'Aktiv (ein Spaziergang pro Tag)',
      THIRD_BUTTON_CAT: 'Regelmäßig, tagsüber',
      THIRD_BUTTON_DOG: 'Ein Spaziergang pro Tag',
      TITLE: 'Wie würden Sie das sportliche Profil Ihres Tieres beschreiben?'
    },
    PET_AGE: {
      FIRST_COMMON_TEXT: 'Jahr(e) und ',
      SECOND_COMMON_TEXT: 'Monat(e)',
      TITLE: 'Wie lautet das Geburtsdatum?'
    },
    PET_GENDER: {
      FEMALE: 'Weiblich',
      FIRST_TITLE: 'Ist Ihr Haustier weiblich oder männlich?',
      MALE: 'Männlich',
      SECOND_TITLE: 'Ist Ihr Haustier kastriert?'
    },
    PET_HEALTH: {
      LAST_VACINATION: 'Wie war seine letzte Impfung?',
      LAST_VET_VISIT: 'Wann war er zuletzt beim Tierarzt?',
      TITLE: 'Gesundheitspunkt'
    },
    PET_IDENTIFICATION: {
      CHIP: 'Gechippt',
      NONE: 'Mein Tier ist nicht gekennzeichnet',
      SELECT_IDENTIFICATION: 'Wie wird er identifiziert?',
      TATTOO: 'Tätowiert'
    },
    PET_INFO: {
      GET_STARTED: 'Los geht\'s!',
      NEED_TO_KNOW: 'Es ist wichtig für uns, dass wir  {{name}} kennenlernen.',
      PETS_DETAILS: 'Füllen Sie die Informationen über Ihr Haustier aus!',
      PETS_GENDER: {
        MIXED_BREED: 'Er ist ein Mischling'
      },
      PET_GENDER_COMMON: 'Zu Beginn ist Ihr Haustier: ',
      SECOND_PETS_DETAILS: 'Füllen Sie die Daten Ihres Haustiers aus'
    },
    PET_MORPH: {
      AVERAGE: 'Normal',
      COMMON_TEXT: 'Circa',
      FIRST_TITLE: 'Wie würden Sie den Körperbau Ihres Tieres beschreiben?',
      HELP_TEXT: 'Wie kann ich den Körperbau meines Tieres bestimmen?\n',
      LEAN: 'Mager',
      OBESE: 'Übergewichtig',
      OVERWEIGHT: 'Übergewichtig',
      SECOND_TITLE: 'Wie viel wiegt Ihr Tier?',
      WEIGH_TEXT: 'Wie viel wiegt Ihr Tier?\n'
    },
    PET_NAME: {
      COMMON_TEXT: 'Zunächst möchten wir Ihr Haustier gern kennenlernen.\u2028 Anschließend können Sie diese Angaben in den Einstellungen ändern. ',
      PLACEHOLDER: 'Name Ihres Tieres',
      TITLE: 'Wie heißt er/sie?'
    },
    PET_RACE: {
      COMMON_TEXT: 'Rasse unbekannt',
      PLACEHOLDER: 'Rasse Ihres Hundes',
      SELECT_BREED: 'Wählen Sie die Rasse aus',
      TITLE: 'Welcher Rasse gehört Ihr Tier an?'
    },
    PET_TYPE: {
      CAT: 'Eine Katze',
      DOG: 'Ein Hund'
    },
    SUMMARY: {
      BIRTH_DATE: 'Geburtsdatum',
      BREED: 'Rasse',
      CONDITION: 'Zustand',
      DAILY_EXERCISE: 'Tägliche Bewegung',
      DELETE: 'Das Profil zurücksetzen.',
      HEALTH: 'Gesundheit',
      IDENTIFICATION: 'Kennzeichnung',
      MODAL: {
        NO: 'Nein, abbrechen',
        SUBTEXT: 'Dann müssen Sie den Vorgang noch einmal von vorne beginnen.',
        TITLE: 'Sind Sie sicher, dass Sie die Informationen Ihres Haustiers zurücksetzen möchten?',
        YES: 'Ja, ich bin sicher'
      },
      MORPHOLOGY: 'Körperbau',
      NAME: 'Namen',
      RECAPTEXT: 'Hier sind die Informationen über Ihren Begleiter.',
      SEX: 'Geschlecht',
      SPECIES: 'Spezies',
      SUBMIT: 'Profil bestätigen',
      UNSPECIFIED: 'felder',
      VALIDATION: 'Validierung des Profils',
      VALIDATION_TEXT: 'Bitte bestätigen Sie Ihre Angaben, bevor Sie fortfahren. ',
      WEIGHT: 'Gewicht'
    }
  },
  activityTracking: {
    ACTIVITY: 'Aktivitätspunkte',
    ACTIVITY_DISTRIBUTION: {
      DATA_COMPARATOR: {
        ACTIVITY_INTENSE: 'Intensive Aktivität',
        ACTIVITY_INTENSE_LEGEND: 'intensive Aktivität {{day}}',
        ACTIVITY_MODERATE: 'Moderate Aktivität',
        ACTIVITY_MODERATE_LEGEND: 'moderate Aktivität {{day}}',
        ACTIVITY_WEAK: 'Niedrige Aktivität',
        ACTIVITY_WEAK_LEGEND: 'niedrige Aktivität {{day}}',
        TEXT: '{{petName}}s {{activity}}e Aktivitätsdauer war gestern {{comparator}} {{day}}.'
      }
    },
    ACTIVITY_REPORT: {
      DATA_COMPARATOR: {
        LEGEND: {
          KM_MONTHLY: 'Kilometer im Durchschnitt pro Tag in diesem Monat',
          KM_WEEKLY: 'Kilometer im Durchschnitt pro Tag in dieser Woche',
          KM_YESTERDAY: 'Kilometer gestern zurückgelegt',
          STEP_MONTHLY: 'Schritte im Durchschnitt pro Tag in diesem Monat',
          STEP_WEEKLY: 'Schritte im Durchschnitt pro Tag in dieser Woche',
          STEP_YESTERDAY: 'Schritte gestern geschafft'
        },
        TEXT_INF: 'kleiner als ',
        TEXT_KM_DAILY: 'Die Anzahl der gestern von {{petName}} zurückgelegten Kilometer ist {{comparator}} vorgestern.',
        TEXT_KM_MONTHLY: 'Die durchschnittliche Anzahl der in dieser Woche von {{petName}} zurückgelegten Kilometer ist {{comparator}} der aktuelle monatliche Durchschnitt. ',
        TEXT_KM_WEEKLY: 'Die Anzahl der gestern von {{petName}} zurückgelegten Kilometer ist {{comparator}} der wöchentliche Durchschnitt.',
        TEXT_SIM: 'genauso hoch wie',
        TEXT_STEP_DAILY: '{{petName}}s gestern zurückgelegte Schrittzahl ist {{comparator}} vorgestern.',
        TEXT_STEP_MONTHLY: 'Die durchschnittliche Schrittzahl, die {{petName}} diese Woche zurückgelegt hat, ist {{comparator}} der monatliche Durchschnitt.',
        TEXT_STEP_WEEKLY: '{{petName}}s gestern zurückgelegte Schrittzahl ist {{comparator}} der wöchentliche Durchschnitt.',
        TEXT_SUP: 'größer als',
        TITLE_KM_TRAVELED: 'Zurückgelegte Kilometer',
        TITLE_STEP: 'Schritte'
      },
      KM_TRAVELED: '{{step}} zurückgelegte Kilometer'
    },
    AIMED_GOAL: {
      ALERT: 'Wir empfehlen ein tägliches Ziel von mindestens 60 Minuten, um Ihr Tier gesund zu halten. ',
      TITLE: 'Setzen Sie sich ein tägliches Aktivitätsziel.'
    },
    DAILY_CHART: '{{petName}}s Aktivität',
    DAILY_GOAL: {
      NOT_RECOMMENDED: 'Ziel nicht empfohlen',
      RECOMMENDED: 'Empfohlenes Ziel',
      SECOND_TITLE: 'Tägliches Ziel',
      TITLE: 'Tägliches Ziel'
    },
    DAILY_STEP: 'Tägliche Schritte',
    DAYS: 'Tag',
    EQUAL: 'genauso lang wie',
    ERROR_PAGE: {
      BUTTON_TEXT: 'Seite schließen',
      TEXT: 'Wir können die Seite derzeit nicht laden. Bitte versuchen Sie es später noch einmal.',
      TITLE: 'Oops, wir haben ein Problem festgestellt...'
    },
    HEADER: {
      ACTIVITY_DISTRIBUTION: {
        TITLE: 'Aktivitätsaufteilung'
      },
      ACTIVITY_REPORT: {
        TITLE: 'Aktivitätsbericht'
      },
      GOAL: {
        TITLE: 'Tägliches Ziel'
      }
    },
    HOME: {
      ACTIVITY_DISTRIBUTION: {
        INTENSE: 'Intensiv',
        MODERATE: 'Moderat',
        WEAK: 'Niedrig'
      },
      ALERT: 'Um zuverlässige Daten zu gewährleisten, muss das Aktivitätstracking mehrere Tage genutzt werden. Während dieser Zeit sammelt und analysiert der Algorithmus Ihre Daten, um die Aktivitätsgewohnheiten und -muster Ihres Tieres zu verstehen. Mit der Zeit werden die Ergebnisse immer genauer und an das Profil Ihres Vierbeiners angepasst.',
      FIRST_SECTION: {
        TITLE: 'Tägliches Ziel'
      },
      INSURANCE: {
        BUTTON: 'Weenect Versicherung - Mehr erfahren',
        TEXT: 'Ein Besuch beim Tierarzt ist erforderlich. Keine Sorge - Unsere Weenect Versicherung kümmert sich um die Kostenrückerstattung der Tierarztkosten. ',
        TITLE: 'Besorgt um {{petName}}s Gesundheit?'
      },
      MODIFY: 'Ändern',
      PROFILE: {
        SUBTITLE: 'Behalten Sie die tägliche Aktivität Ihres Tieres im Blick, um es fit zu halten. '
      },
      SECOND_SECTION: {
        SUBTITLE: 'Am heutigen Tag',
        TITLE: 'Aktivitätsaufteilung'
      },
      SEE_MORE: 'Mehr',
      THIRD_SECTION: {
        TITLE: 'Highlights'
      }
    },
    HOUR: '{{count}} h',
    HOURS: 'Stunden',
    INTERVAL: {
      TEXT: 'Verschieben Sie die Ränder des Diagramms, um den angezeigten Zeitraum zu ändern.',
      TITLE: 'Zeitraum auswählen'
    },
    KEY_POINTS: 'Highlights',
    KILOMETERS: 'Kilometer',
    LESS: 'kürzer als',
    MINUTE_ACTIVITY_one: '{{count}} Minute Aktivität',
    MINUTE_ACTIVITY_other: '{{count}} Minuten Aktivität',
    MINUTE_one: '{{count}} Minute',
    MINUTE_other: '{{count}} Minuten',
    MIN_one: '{{count}} min',
    MIN_other: '{{count}} min',
    MONTHLY_STEP: 'Monatliche Schritte',
    ONBOARDING: {
      FIRST_STEP: {
        ALERT: 'Im Flugzeugmodus wird das Aktivitätstracking weiterhin aufgezeichnet. Sobald Sie die WLAN-Zone verlassen, wird es im Dashboard angezeigt.',
        BUTTON: 'Starten',
        MESSAGE: 'Mit diesem Tool können Sie die täglichen Aktivitäten Ihres Vierbeiners verfolgen.  Wie lange hat er geschlafen? Wie viele Schritte hat er heute gemacht? All das wird schon bald kein Geheimnis mehr für Sie sein!',
        TITLE: 'Willkommen beim Aktivitätstracking Ihres Haustiers!'
      },
      SECONDE_STEP: {
        ALERT: 'Wir empfehlen ein tägliches Ziel von mindestens 60 Minuten, um Ihr Tier gesund zu halten. ',
        BUTTON: 'Speichern',
        PROFILE: {
          SUBTITLE: 'Behalten Sie die tägliche Aktivität Ihres Tieres im Blick, um es fit zu halten. ',
          TITLE: '{{petName}}s Aktivität'
        },
        TITLE: 'Setzen Sie sich ein tägliches Ziel für die Aktivität.'
      }
    },
    PERIOD: {
      DAY: 'Tag',
      MONTH: 'Monat',
      TITLE: 'Zeitraum auswählen',
      WEEK: 'Woche',
      YEAR: 'Jahr'
    },
    PLUS: 'länger als',
    RETURN_HOME: 'Zurück zur Startseite',
    STEP: 'Schritte',
    STEP_SAVED: '{{step}} Schritte',
    TEXT: 'Anhand dieser Grafik können Sie die Aktivität Ihres Tieres verfolgen. Die Messung dieser Aktivität erfolgt über ein Intervall, das bei 0 beginnt und keine Höchstgrenze hat. Jeder Aktivitätspunkt steht für eine Beschleunigung, das heißt: für eine Bewegung, die Ihr Tier ausführt.',
    TOTAL: 'Gesamt',
    WEEKDAYS: {
      FRIDAY: 'Freitag',
      FRIDAY_COMPARATIVE: 'am Freitag',
      MONDAY: 'Montag',
      MONDAY_COMPARATIVE: 'am Montag',
      SATURDAY: 'Samstag',
      SATURDAY_COMPARATIVE: 'am Samstag',
      SUNDAY: 'Sonntag',
      SUNDAY_COMPARATIVE: 'am Sonntag',
      THURSDAY: 'Donnerstag',
      THURSDAY_COMPARATIVE: 'am Donnerstag',
      TUESDAY: 'Dienstag',
      TUESDAY_COMPARATIVE: 'am Dienstag',
      WEDNESDAY: 'Mittwoch',
      WEDNESDAY_COMPARATIVE: 'am Mittwoch'
    },
    WEEKLY_STEP: 'Wöchentliche Schritte',
    YESTERDAY: 'gestern'
  },
  alertCustomer: {
    BODY0: 'unser SIM-Kartenanbieter wartet seine Systeme derzeit.',
    BODY1: '<1>Während dieser Wartung wird die Aktivierungszeit der SIM-Karte auf 24 Stunden verlängert</1>.',
    BODY2: 'Wir entschuldigen uns für die daraus entstehenden Unannehmlichkeiten und für diese Situation, auf die wir leider keinen Einfluss haben.\n\nFür weitere Informationen können Sie unseren Support unter <1>family@weenect.com</1> oder telefonisch unter +49.32.212.29.33.36  kontaktieren.',
    FOOTER: 'Das Weenect Team'
  },
  animalInfo: {
    LANDING_PAGE: {
      BUTTON: 'Starten',
      TEXT: 'Stellen Sie uns Ihren Vierbeiner vor. Sie können diese Informationen später in den Einstellungen ändern. ',
      TITLE: 'Geben Sie die Informationen Ihres Haustieres ein!',
      WARNING: 'Diese Informationen sind notwendig für das genaueste Aktivitäts-Tracking und um Ihre Weenect-Erfahrung zu personalisieren.'
    },
    NEXT_BUTTON: 'Weiter',
    PROFIL_VALIDATION: {
      ACTIVITY: 'Aktivität und Verhalten',
      BUTTON: 'Profil bestätigen',
      INDENTIFICATION_INFOS: 'Identifikationsinfos',
      MEDICAL_HISTORY: 'Medizinische Geschichte',
      PERSONAL_INFOS: 'Persönliche Infos',
      PHYSICAL: 'Physische Merkmale',
      SEX: 'Geschlecht',
      SPECIES: 'Tierart',
      TITLE: 'Profilvalidierung',
      UNIQUE_IDENTIFICATION: 'Eindeutige Identifikation'
    },
    QUESTION: 'Frage',
    QUESTION_EIGHT: {
      NO: 'Nein',
      NOT_STERILIZED: 'Nicht kastriert',
      QUESTION: 'Ist Ihr Haustier sterilisiert?',
      STERILIZATION_STATUS: 'Kastrationsstatus',
      STERILIZED: 'Kastriert',
      YES: 'Ja'
    },
    QUESTION_ELEVEN: {
      EVERYTIME: 'Oft',
      EXIT_FREQUENCY: 'Häufigkeit der Ausgänge',
      MOSTLY: 'Ab und zu',
      OFTEN: 'Regelmäßi',
      QUESTION: 'Wie oft geht Ihr Vierbeiner nach draußen?',
      RARELY: 'Nur ausnahmsweise'
    },
    QUESTION_FIVE: {
      QUESTION: 'Wie viel wiegt Ihr Haustier?',
      WEIGHT: 'Gewicht'
    },
    QUESTION_FOUR: {
      FEMALE: 'Weiblich',
      MALE: 'Männlich',
      QUESTION: 'Was ist das Geschlecht Ihres Tieres?'
    },
    QUESTION_NINE: {
      LAST_VISIT: 'Letzter Besuch',
      QUESTION: 'Wann war Ihr Tier das letzte Mal beim Tierarzt?'
    },
    QUESTION_ONE: {
      CAT: 'Eine Katze',
      DOG: 'Ein Hund',
      QUESTION: 'Welches Profil hat Ihr Tier?'
    },
    QUESTION_SEVEN: {
      CHIPED: 'Gechipt',
      IDENTIFICATION: 'Identifikation',
      NOT_IDENTIFIED: 'Mein Tier ist nicht identifiziert',
      QUESTION: 'Wie ist Ihr Haustier gekennzeichnet?',
      TATTOED: 'Tätowiert'
    },
    QUESTION_SIX: {
      QUESTION: 'Was ist die Rasse Ihres Tieres?',
      RACE: 'Rasse'
    },
    QUESTION_TEN: {
      LAST_VACCIN: 'Letzte Impfung',
      QUESTION: 'Wann war die letzte Impfung?'
    },
    QUESTION_THREE: {
      BIRTHDATE: 'Geburtsdatum',
      QUESTION: 'Wann ist Ihre Fellnase geboren?'
    },
    QUESTION_TWO: {
      NAME: 'Name',
      QUESTION: 'Wie heißt es?'
    }
  },
  breed: {
    CAT: {
      ABYSSINIAN: 'Abessinier',
      AMERICAN_BOBTAIL: 'American Bobtail',
      AMERICAN_CURL: 'American Curl',
      AMERICAN_SHORTHAIR: 'American Shorthair',
      AMERICAN_WIREHAIR: 'American Wirehair',
      BALINESE: 'Balinesenkatze',
      BENGAL: 'Bengalkatze',
      BIRMAN: 'Birma-Katze',
      BOMBAY: 'Bombay-Katze',
      BURMESE: 'Burma-Katze',
      BURMILLA: 'Burmilla',
      CALIFORNIA_SPANGLED_CAT: 'California Spangled',
      CHANTILLYTIFFANY: 'Tiffanie',
      CHARTREUX: 'Kartäuser-Katze',
      CORNISH_REX: 'Cornish Rex',
      CYMRIC: 'Cymric',
      DEVON_REX: 'Devon Rex',
      EGYPTIAN_MAU: 'Ägyptische Mau',
      EUROPEAN: 'Europäer',
      EXOTIC_SHORTHAIR: 'Exotische Kurzhaarkatze',
      HAVANA: 'Havana-Katze',
      JAPANESE_BOBTAIL: 'Japanese Bobtail',
      JAVANESE: 'Javanese-Katze',
      KORAT: 'Korat-Katze',
      MAINE_COON: 'Maine Coon-Katze',
      MALAYAN: 'Asian Katze (Malayan)',
      MANX: 'Manx',
      MUNCHKIN: 'Munchkin',
      NEBELUNG: 'Nebelung',
      NORWEGIAN: 'Norwegisch',
      NORWEGIAN_FOREST_CAT: 'Norwegische Waldkatze',
      OCICAT: 'Ocicat',
      ORIENTAL_LONGHAIR: 'Mandarin-Katze',
      ORIENTAL_SHORTHAIR: 'Orientalisch Kurzhaar',
      PERSIAN: 'Perserkatze',
      RAGDOLL: 'Ragdoll-Katze',
      RUSSIAN_BLUE: 'Russisch Blau',
      SAFARI_CAT: 'Safari-Katze',
      SCOTTISH_FOLD: 'Schottische Faltohrkatze',
      SELKIRK: 'Selkirk Rex',
      SIAMESE: 'Siamkatze',
      SIBERIAN: 'Sibirische Katze',
      SINGAPURA: 'Singapura',
      SNOWSHOE: 'Snowshoe',
      SOMALI: 'Somalikatze',
      SPHYNX: 'Sphynx-Katze',
      THAI: 'Thaikatze',
      TONKINESE: 'Tonkinese',
      TURKISH_ANGORA: 'Türkisch Angora',
      TURKISH_VAN: 'Türkisch Van'
    },
    DOG: {
      AFFENPINSCHER: 'Affenpinscher',
      AFGHAN_HOUND: 'Afghanischer Windhund',
      AIREDALE_TERRIER: 'Airedale Terrier',
      AKITA: 'Akita',
      AKITA_INU_GREAT_JAPANESE_DOG: 'Großer Japanischer Hund',
      ALASKAN_MALAMUTE: 'Alaskischer Malamute',
      ALPINE_DACHSBRACKE: 'Alpenländische Dachsbracke',
      AMERICAN_BULLDOG: 'Amerikanische Bulldogge',
      AMERICAN_COCKER_SPANIEL: 'Amerikanischer Cocker Spaniel',
      AMERICAN_FOXHOUND: 'Amerikanischer Foxhound (Foxhound)',
      AMERICAN_STAFFORDSHIRE_TERRIER: 'American Staffordshire Terrier',
      AMERICAN_WATER_SPANIEL: 'American Water Spaniel (Amerikanischer Wasserhund)',
      ANATOLIAN_SHEPHERD_DOG: 'Anatolischer Schäferhund',
      APPENZELLER_SENNENHUND: 'Appenzeller Sennenhund',
      ARDENNES_CATTLE_DOG: 'Ardennen-Bouvier',
      ARGENTINE_DOGO: 'Argentinische Dogge',
      ARIEGEOIS: 'Ariégeois',
      ARIEGE_POINTER: 'Braque de l\'Ariège ',
      ARTOIS_HOUND: 'Artois-Hund',
      ATLAS_SHEPHERD_DOG_AIDI: 'Atlashund (Aïdi - Berger de l\'Atlas)',
      AUSTRALIAN_CATTLE_DOG: 'Australische Bulldogge',
      AUSTRALIAN_KELPIE: 'Australian Kelpie',
      AUSTRALIAN_SHEPHERD: 'Australian Shepherd',
      AUSTRALIAN_TERRIER: 'Australischer Terrier',
      AUSTRIAN_BLACK_AND_TAN_HOUND: 'Brandlbracke',
      AUSTRIAN_SHORTHAIRED_PINSCHER: 'Österreichischer Kurzhaar Pinscher',
      AZAWAKH: 'Azawakh',
      BALKAN_HOUND: 'Balkanischer Laufhund',
      BARBET: 'Barbet',
      BASENJI: 'Basenji',
      BASSET_ARTESIEN_NORMAND: 'Basset Artésien Normand',
      BASSET_BLEU_DE_GASCOGNE: 'Basset bleu de Gascogne',
      BASSET_FAUVE_DE_BRETAGNE: 'Basset Fauve de Bretagne',
      BASSET_HOUND: 'Basset Hound',
      BAVARIAN_MOUNTAIN_SCENTHOUND: 'Bayerischer Gebirgsschweißhund',
      BEAGLE: 'Beagle',
      BEARDED_COLLIE: 'Bearded Collie',
      BEAUCERON: 'Beauceron ',
      BEDLINGTON_TERRIER: 'Bedlington Terrier',
      BELGIAN_GRIFFON: 'Belgischer Griffon',
      BELGIAN_SHEPHERD_DOG_GROENENDAEL: 'Belgischer Schäferhund Groenendael',
      BELGIAN_SHEPHERD_DOG_LAEKENOIS: 'Belgischer Schäferhund Laekenois',
      BELGIAN_SHEPHERD_DOG_MALINOIS: 'Belgischer Schäferhund Malinois',
      BELGIAN_SHEPHERD_DOG_TERVUREN: 'Belgischer Schäferhund Tervueren',
      BERGAMASCO_SHEPHERD: 'Bergamasker Hirtenhund',
      BERNER_NIEDERLAUFHUND: 'Schweizerischer Niederlaufhund',
      BERNESE_HOUND: 'Schweizer Laufhund',
      BERNESE_MOUNTAIN_DOG: 'Berner Sennenhund',
      BICHON_FRISE: 'Bichon Frisé (Kraushaar)',
      BILLY_DOG: 'Billy',
      BLACK_AND_TAN_COONHOUND: 'Black and Tan Coonhound',
      BLOODHOUND_SAINTHUBERT_DOG: 'Bloodhound',
      BLUE_PICARDY_SPANIEL: 'Epagneul bleu de Picardie',
      BOHEMIAN_WIREHAIRED_POINTING_GRIFFON: 'Tschechischer Griffon d\'arrêt',
      BOLOGNESE_DOG: 'Bologneser Bichon',
      BORDEAUX_MASTIFF_FRENCH_MASTIFF: 'Bordeauxdogge',
      BORDER_COLLIE: 'Border Collie',
      BORDER_TERRIER: 'Border Terrier',
      BORZOI: 'Barzoi (Russischer Windhund)',
      BOSNIAN_COARSEHAIRED_HOUND: 'Bosnischer Laufhund',
      BOSTON_TERRIER: 'Boston Terrier',
      BOURBONNAIS_POINTER: 'Bracke aus dem Bourbonnais',
      BOUVIER_DES_FLANDRES: 'Flandrischer Sennenhund',
      BOXER_DOG: 'Boxer',
      BRAQUE_D_AUVERGNE: 'Auvergne-Bracke',
      BRAZILIAN_TERRIER: 'Brasilianischer Terrier',
      BRIARD: 'Briard ',
      BRIQUET_GRIFFON_VENDEEN: 'Briquet Griffon Vendéen',
      BRITTANY_SPANIEL: 'Epagneul Breton',
      BROHOLMER: 'Broholmer',
      BRUNO_JURA_HOUND: 'Kleiner Laufhund aus dem Jura',
      BRUNO_JURA_HOUND_BRUNO_TYPE: 'Bruno du Jura Typ Bruno',
      BRUNO_JURA_HOUND_ST_HUBERT_TYPE: 'Bruno du Jura Typ Saint Hubert',
      BRUSSELS_GRIFFON: 'Brüsseler Griffon',
      BULLMASTIFF: 'Bullmastiff',
      BULL_TERRIER_ENGLISH_BULL_TERRIER: 'Bull Terrier (English Bull Terrier)',
      BURGOS_POINTER: 'Bracke von Burgos (Perdiguero de Burgos)',
      CAIRN_TERRIER: 'Cairn Terrier',
      CANAAN_DOG: 'Hund von Kanaan',
      CANARY_ISLAND_PODENCO: 'Podenco Canario',
      CANE_CORSO: 'Cane Corso',
      CAO_DE_CASTRO_LABOREIRO: 'Cao de Castro Laboreiro',
      CAO_FILA_DE_SAO_MIGUEL: 'Cao Fila de Sao Miguel',
      CATAHOULA_LEOPARD_DOG: 'Hund Leopard Catahoula (Louisiana Catahoula Leopard Dog)',
      CATALAN_SHEEPDOG: 'Katalanischer Schäferhund',
      CAUCASIAN_SHEPHERD_DOG: 'Kaukasischer Schäferhund',
      CAVALIER_KING_CHARLES_SPANIEL: 'Cavalier King Charles',
      CA_DE_BOU_PERRO_DE_PRESA: 'Perro de Presa Mallorquin',
      CENTRAL_ASIAN_SHEPHERD_DOG: 'Zentralasiatischer Owtscharka',
      CESKY_TERRIER: 'Tschechischer Terrier',
      CHESAPEAKE_BAY_RETRIEVER: 'Chesapeake Bay Retriever',
      CHIHUAHUA: 'Chihuahua',
      CHINESE_CRESTED_DOG: 'Chinesischer Schopfhund',
      CHOW_CHOW: 'Chow Chow',
      CIRNECO_DELL_ETNA: 'Cirneco dell’ Etna ',
      CLUMBER_SPANIEL: 'Clumber',
      CONTINENTAL_TOY_SPANIEL: 'Kontinentaler Zwergspaniel',
      CORSICAN_DOG: 'Korsischer Hund',
      COTON_DE_TULEAR: 'Coton de Tulear',
      CROATIAN_SHEEPDOG: 'Kroatischer Schäferhund',
      CURLYCOATED_RETRIEVER: 'Retriever Curly Coated ',
      CZECHOSLOVAKIAN_WOLFDOG: 'Tschechischer Wolfshund',
      DACHSHUND_LONGHAIRED: 'Langhaar Dackel',
      DACHSHUND_LONGHAIRED_RABBIT: 'Dackel für die Kaninchenjagd (Langhaar)',
      DACHSHUND_SMOOTHHAIRED: 'Kurzhaar Dackel',
      DACHSHUND_SMOOTHHAIRED_RABBIT: 'Dackel für die Kaninchenjagd (Kurzhaar)',
      DACHSHUND_WIREHAIRED: 'Rauhhaariger Dackel',
      DACHSHUND_WIREHAIRED_RABBIT: 'Dackel für die Kaninchenjagd (Rauhaar)',
      DALMATIAN: 'Dalmatiner',
      DANDIE_DINMONT_TERRIER: 'Dandie Dinmont Terrier',
      DOBERMAN_PINSCHER: 'Dobermann',
      DOGO_CANARIO: 'Dogo Canario',
      DOGUE_DE_BORDEAUX: 'Okzitanischer Löwe (Leonberger)',
      DRENTSE_PARTRIDGE_DOG: 'Drentscher Hühnerhund',
      DREVER: 'Drever',
      DUNKER: 'Dunker',
      DUPUY_POINTER: 'Dupuy-Bracke',
      DUTCH_SHEPHERD_LONGHAIRED_VARIETY: 'Holländischer Schäferhund Langhaar',
      DUTCH_SHEPHERD_ROUGHHAIRED_VARIETY: 'Holländischer Schäferhund Rauhaar',
      DUTCH_SHEPHERD_SHORTHAIRED_VARIETY: 'Kurzhaariger Holländischer Schäferhund',
      DUTCH_SMOUSHOND: 'Kleiner Holländischer Jagdhund',
      EAST_SIBERIAN_LAIKA: 'Laika aus Ostsibirien',
      ENGLISH_BULLDOG: 'Englische Bulldogge',
      ENGLISH_COCKER_SPANIEL: 'Englischer Cocker Spaniel',
      ENGLISH_POINTER: 'Englischer Pointer',
      ENGLISH_SETTER: 'Englischer Setter',
      ENGLISH_SPRINGER_SPANIEL: 'Englischer Springer Spaniel',
      ENGLISH_TOY_TERRIER: 'Toy Terrier Schwarz und Feuer',
      ENTLEBUCHER_MOUNTAIN_DOG: 'Entlebucher Sennenhund',
      EURASIER: 'Eurasier',
      FAWN_BRITTANY_BASSET: 'Griffon Fauve de Bretagne',
      FIELD_SPANIEL: 'Field Spaniel',
      FILA_BRASILEIRO: 'Fila Brasileiro',
      FINNISH_HOUND: 'Finnischer Laufhund',
      FINNISH_LAPPHUND: 'Finnischer Lapphund',
      FINNISH_SPITZ: 'Finnischer Spitz',
      FLATCOATED_RETRIEVER: 'Retriever Flat Coated ',
      FOXHOUND_ENGLISH_FOXHOUND: 'Foxhound (Englischer Foxhound)',
      FRENCH_BULLDOG: 'Französische Bulldogge',
      FRENCH_SPANIEL: 'Französischer Spaniel',
      FRENCH_WHITE_AND_BLACK_HOUND: 'Französischer Laufhund weiß und schwarz',
      FRENCH_WHITE_AND_ORANGE_HOUND: 'Französisch Laufhund weiß und orange',
      FRISIAN_WATER_DOG: 'Friesischer Wasserhund',
      GASCONY_POINTER: 'Französische Bracke Typ Gascogne',
      GERMAN_LONGHAIRED_POINTER: 'Deutsch Langhaar',
      GERMAN_PINSCHER: 'Pinscher Mittelgroß',
      GERMAN_SHEPHERD: 'Deutscher Schäferhund',
      GERMAN_SHORTHAIRED_POINTER: 'Deutsche Kurzhaar Bracke',
      GERMAN_SPITZ: 'Deutscher Spitz (Groß)',
      GERMAN_WIREHAIRED_POINTER: 'Deutsche Rauhaarbracke',
      GIANT_SCHNAUZER: 'Riesenschnauzer',
      GLEN_OF_IMAAL_TERRIER: 'Irischer Terrier Glen of Imaal',
      GOLDEN_RETRIEVER: 'Golden Retriever',
      GORDON_SETTER: 'Gordon Setter',
      GOTH_SHEPHERD_DOG: 'Gotenhund (Västgötaspets)',
      GRAND_ANGLOFRANCAIS_BLANC_ET_NOIR: 'Grand Anglo-Français Weiß und Schwarz',
      GRAND_ANGLOFRANCAIS_BLANC_ET_ORANGE: 'Grand Anglo-Français Weiß und Orange',
      GRAND_ANGLOFRANCAIS_TRICOLORE: 'Grand Anglo-Français Dreifarbig',
      GRAND_BASSET_GRIFFON_VENDEEN: 'Grand Basset Griffon Vendéen',
      GRAND_BLEU_DE_GASCOGNE: 'Großer Blauer Gascogne-Laufhund',
      GRAND_GASCONSAINTONGEOIS_HOUND: 'Großer Saintongeois Gascon',
      GRAND_GRIFFON_VENDEEN: 'Großer Griffon Vendéen',
      GRAND_SPANISH_HOUND: 'Spanischer Laufhund (groß)',
      GREATER_SWISS_MOUNTAIN_DOG: 'Großer Schweizer Sennenhund',
      GREAT_DANE: 'Deutsche Dogge',
      GREAT_PYRENEES: 'Pyrenäenberghund ',
      GREENLAND_DOG: 'Grönland-Eskimo',
      GREYHOUND: 'Greyhound',
      HALDEN_HOUND: 'Haldenstøver Halden-Laufhund',
      HAMILTONSTOVARE: 'Hamilton Stovare',
      HANOVERIAN_SCENTHOUND: 'Hannoverscher Schweisshund',
      HANOVER_HOUND: 'Deutsche Kurzhaar-Bracke',
      HARRIER: 'Beagle Harrier',
      HAVANESE_DOG: 'Havaneser-Bichon',
      HELLENIC_HOUND: 'Griechischer Laufhund',
      HOKKAIDO: 'Hokkaido',
      HOVAWART: 'Hovawart',
      HUNGARIAN_GREYHOUND: 'Ungarischer Windhund',
      HUNGARIAN_KUVASZ: 'Kuvasz (Ungarischer Schäferhund)',
      HYGEN_HOUND: 'Hygenhund',
      IBIZAN_HOUND_SHORT_HAIRED: 'Podenco Ibicenco de pelo corto (Ibizenkischer Kurzhaarteckel)',
      IBIZAN_HOUND_WIRE_HAIRED: 'Podenco Ibicenco de pelo duro',
      ICELANDIC_SHEEPDOG: 'Isländischer Schäferhund',
      IRISH_RED_AND_WHITE_SETTER: 'Roter Irischer Setter',
      IRISH_SETTER: 'Irischer Setter Rot und Weiß',
      IRISH_TERRIER: 'Irischer Terrier',
      IRISH_WATER_SPANIEL: 'Irischer Wasserhund',
      IRISH_WOLFHOUND: 'Irischer Windhund',
      ISTRIAN_COARSEHAIRED_HOUND: 'Istrischer Laufhund Rauhaar',
      ISTRIAN_SHORTHAIRED_HOUND: 'Istrischer Kurzhaar-Laufhund',
      ITALIAN_GREYHOUND: 'Kleiner Italienischer Windhund',
      ITALIAN_POINTING_DOG: 'Italienische Bracke',
      ITALIAN_SHORTHAIRED_HOUND: 'Italienischer Laufhund, kurzhaarig',
      ITALIAN_SPITZ: 'Italienischer Spitz',
      ITALIAN_WIREHAIRED_HOUND: 'Italienischer Laufhund, Rauhaar',
      JACK_RUSSELL_TERRIER: 'Jack Russell Terrier',
      JAGDTERRIER: 'Jagd Terrier',
      JAPANESE_SPANIEL_CHIN: 'Japanischer Spaniel',
      JAPANESE_SPITZ: 'Japanischer Spitz',
      JAPANESE_TERRIER: 'Japanischer Terrier',
      KAI_KEN: 'Kai',
      KARELIAN_BEAR_DOG: 'Karelischer Bärenhund',
      KARST_SHEPHERD: 'Karst-Schäferhund ',
      KERRY_BLUE_TERRIER: 'Kerry Blue Terrier',
      KISHU_KEN: 'Kishu',
      KOMONDOR: 'Komondor',
      KOREAN_JINDO_DOG: 'Korea Jindo Dog',
      KROMFOHRLANDER: 'Kromfohrländer',
      KYILEO: 'Kyi Leo',
      LABRADOR: 'Labrador',
      LAKELAND_TERRIER: 'Lakeland Terrier',
      LANDSEER: 'Landseer',
      LAPPONIAN_HERDER: 'Finnischer Lapplandschäferhund',
      LARGE_MUNSTERLANDER: 'Großer Münsterländer',
      LEONBERGER: 'Leonberger (Leos)',
      LHASA_APSO: 'Lhasa Apso',
      LOWCHEN_LITTLE_LION_DOG: 'Löwchen',
      LUCERNE_HOUND: 'Luzerner Laufhund',
      LUZERNER_LAUFHUND: 'Kleiner Laufender Luzerner Hund',
      MAJORCA_SHEPHERD_DOG: 'Spanischer Schäferhund von Mallorca (Ca de Bestiar)',
      MALTESE_DOG: 'Malteser Bichon',
      MANCHESTER_TERRIER: 'Manchester Terrier',
      MAREMMA_SHEEPDOG: 'Maremmen-Abruzzen-Schäferhund',
      MASTIFF_FRENCH_MASTIFF: 'LOF-registrierter Mastiff',
      MEDIUM_POODLE: 'Pudel (Mittel)',
      MEXICAN_HAIRLESS_DOG: 'Mexikanischer Nackthund ',
      MINIATURE_BULL_TERRIER: 'Bullterrier Miniatur',
      MINIATURE_DACHSHUND_LONGHAIRED: 'Zwergdackel mit langem Fell',
      MINIATURE_DACHSHUND_SMOOTHHAIRED: 'Zwergdackel mit kurzem Haar',
      MINIATURE_DACHSHUND_WIREHAIRED: 'Zwergdackel Rauhhaar',
      MINIATURE_PINSCHER: 'Zwergpinscher',
      MINIATURE_POODLE: 'Pudel (Zwerg)',
      MINIATURE_SCHNAUZER: 'Zwergschnauzer',
      MIXED_BREED_CROSSBREED: 'Mischling',
      MUDI: 'Mudi (Ungarischer Schäferhund)',
      NEAPOLITAN_MASTIFF: 'Neapolitanischer Mastiff (Mastino Napoletano)',
      NEWFOUNDLAND: 'Neufundländer',
      NORFOLK_TERRIER: 'Norfolk Terrier',
      NORRBOTTENSPETS: 'Norrbottenspitz',
      NORWEGIAN_BUHUND: 'Norwegischer Buhund',
      NORWEGIAN_ELKHOUND_BLACK: 'Norwegischer Elchhund schwarz',
      NORWEGIAN_ELKHOUND_GRAY: 'Norwegischer Elchhund grau',
      NORWEGIAN_LUNDEHUND: 'Norwegischer Lundehund',
      NORWICH_TERRIER: 'Norwich Terrier',
      NOVA_SCOTIA_DUCK_TOLLING_RETRIEVER: 'Neu-Schottland-Retriever',
      OLD_DANISH_POINTER: 'Altdänischer Vorstehhund',
      OLD_ENGLISH_SHEEPDOG: 'Bobtail',
      OTTERHOUND: 'Otter-Hund',
      PARSON_RUSSELL_TERRIER: 'Parson Jack Russell Terrier',
      PEKINGESE: 'Pekinese',
      PERUVIAN_HAIRLESS_DOG_LARGE: 'Peruanischer Nackthund (groß)',
      PERUVIAN_HAIRLESS_DOG_MEDIUM: 'Peruanischer Nackthund (mittelgroß)',
      PERUVIAN_HAIRLESS_DOG_SMALL: 'Peruanischer Nackthund (klein)',
      PETIT_BASSET_GRIFFON_VENDEEN: 'Anglo Français de Petite Vénerie',
      PETIT_BRABANCON: 'Kleiner Brabanter',
      PETIT_GASCON_SAINTONGEOIS: 'Kleiner Gascogner Saintongeois',
      PHARAOH_HOUND: 'Pharaonenhund',
      PICARDY_SHEPHERD: 'Picardischer Schäferhund (Berger de Picardie)',
      PICARDY_SPANIEL: 'Picard Spaniel',
      POITEVIN: 'Poitevin',
      POLISH_HOUND: 'Polnischer Bracke',
      POLISH_LOWLAND_SHEEPDOG: 'Polnischer Niederungshütehund',
      PONTAUDEMER_SPANIEL: 'Epagneul Pont Audemer',
      PORCELAINE: 'Porcelaine',
      PORTUGUESE_PODENGO_GRANDE: 'Portugiesischer Podengo Grande',
      PORTUGUESE_PODENGO_MEDIO: 'Portugiesischer Podengo Medio',
      PORTUGUESE_PODENGO_PEQUENO: 'Portugiesischer Podengo Pequeno',
      PORTUGUESE_POINTER: 'Portugiesische Bracke',
      PORTUGUESE_SHEEPDOG: 'Portugiesischer Schäferhund',
      PORTUGUESE_SHEEPDOG_LONGHAIRED: 'Serra da Estrela Berghund Langhaar ',
      PORTUGUESE_SHEEPDOG_SHORTHAIRED: 'Serra da Estrela Berghund Kurzhaar ',
      PORTUGUESE_WATER_DOG: 'Portugiesischer Wasserhund',
      POSAVAC_HOUND: 'Posavatzer Laufhund',
      PRAGUE_RATTER: 'Prager Rattler',
      PUDEL_POINTER: 'Pudel Pointer',
      PUG: 'Mops',
      PULI_HUNGARIAN_SHEPHERD: 'Puli (Ungarischer Schäferhund)',
      PUMI_HUNGARIAN_SHEPHERD: 'Pumi (Ungarischer Schäferhund)',
      PYRENEAN_MOUNTAIN_DOG: 'Mastiff der Pyrenäen',
      PYRENEAN_POINTER: 'Französische Bracke Typ Pyrenäen',
      PYRENEAN_SHEEPDOG_LONGFACED_VARIETY: 'Langhaariger Pyrenäen-Schäferhund',
      PYRENEAN_SHEEPDOG_SMOOTHFACED_VARIETY: 'Pyrenäenschäferhund mit kurzem Gesicht',
      RAFEIRO_DE_ALENTEJO: 'Rafeiro de Alentejo',
      REDBONE_COONHOUND: 'Redbone Coonhound',
      REDTICK_COONHOUND: 'Redtick Coonhound',
      RHODESIAN_RIDGEBACK: 'Rhodesian Ridgeback',
      ROMAGNA_WATER_DOG: 'Romagnolischer Wasserhund',
      ROTTWEILER: 'Rottweiler',
      ROUGH_COLLIE: 'Langhaar-Collie',
      RUSSIAN_BLACK_TERRIER: 'Russischer Schwarzer Terrier',
      RUSSOEUROPEAN_LAIKA: 'Europäischer Laika Russo',
      SAARLOOSWOLFHOND: 'Saarlooswolfhund',
      SAINTUSUGE_SPANIEL: 'Epagneul de Saint-Usuge',
      SAINT_BERNARD: 'Bernhardiner',
      SALUKI: 'Saluki',
      SAMOYED: 'Samojede',
      SANSHU: 'Sanshu',
      SCHAPENDOES: 'Schapendoes',
      SCHILLERSTOVARE: 'Schiller Stovare',
      SCHIPPERKE: 'Schipperke',
      SCHWEIZER_LAUFHUND: 'Schwyz Laufhund',
      SCOTTISH_DEERHOUND: 'Schottischer Windhund',
      SCOTTISH_TERRIER: 'Schottischer Terrier',
      SEALYHAM_TERRIER: 'Sealyham Terrier',
      SHAR_PEI: 'Shar Peï (Shar Peï)',
      SHETLAND_SHEEPDOG: 'Shetland-Schäferhund',
      SHIBA_INU: 'Shiba (Shiba Inu)',
      SHIH_TZU: 'Shih Tzu',
      SHIKOKU: 'Sikoku',
      SHORTHAIRED_HUNGARIAN_POINTER: 'Ungarische Kurzhaar-Bracke',
      SIBERIAN_HUSKY: 'Sibirischer Husky',
      SILKY_TERRIER: 'Silky Terrier (Sydney Silky)',
      SKYE_TERRIER: 'Skye Terrier (Skye Terrier)',
      SLOUGHI: 'Sloughi',
      SLOVAKIAN_WIREHAIRED_POINTER: 'Slowakische Rauhhaarbracke',
      SLOVAK_CUVAC: 'Slowakischer Schäferhund',
      SLOVAK_HOUND: 'Slowakischer Laufhund',
      SMALAND_HOUND: 'Smalandsstovare',
      SMALL_MUNSTERLANDER: 'Kleiner Münsterländer',
      SMALL_SPANISH_HOUND: 'Spanischer Laufhund (klein)',
      SMOOTH_COLLIE: 'Kurzhaar-Collie',
      SMOOTH_FOX_TERRIER_ENGLISH_FOX_TERRIER: 'Foxterrier Glatthaar (Englischer Fox)',
      SOFTCOATED_WHEATEN_TERRIER: 'Irischer Terrier mit weichem Fell',
      SOUTH_RUSSIAN_OVCHARKA: 'Südrussische Owtscharka',
      SPANISH_GREYHOUND_GALGO_ESPANOL: 'Spanischer Galgo',
      SPANISH_MASTIFF: 'Spanischer Mastiff',
      SPANISH_WATER_DOG: 'Spanischer Wasserhund',
      SPINONE_ITALIANO: 'Spinone Italiano',
      STABYHOUN: 'Stabyhoun (Friesischer Vorstehhund)',
      STAFFORDSHIRE_BULL_TERRIER: 'StaffordShire Bull Terrier LOF',
      STANDARD_POODLE: 'Pudel (Groß)',
      STANDARD_SCHNAUZER: 'Mittelgroßer Schnauzer',
      STICHELHAAR: 'Stichelhaar',
      STYRIAN_COARSEHAIRED_HOUND: 'Steirische Rauhhaarbracke',
      ST_GERMAIN_POINTING_DOG: 'Bracke Saint Germain',
      SUSSEX_SPANIEL: 'Sussex Spaniel',
      SWEDISH_ELKHOUND: 'Schwedischer Elchhund',
      SWEDISH_LAPPHUND: 'Schwedischer Lapphund',
      TATRA_MOUNTAIN_SHEEPDOG: 'Polnischer Podhale-Tatra-Schäferhund',
      TECKEL_DE_CHASSE_AU_LAPIN_POIL_DUR: 'Transsylvanischer Laufhund',
      TIBETAN_MASTIFF: 'Tibetanische Dogge',
      TIBETAN_SPANIEL: 'Tibet-Spaniel',
      TIBETAN_TERRIER: 'Tibetanischer Terrier',
      TOSA: 'Tosa eingetragen LOF',
      TOY_POODLE: 'Pudel (Toy) (Miniaturpudel)',
      TRICOLOUR_HOUND: 'Französisch Laufhund Dreifarbig',
      TYROLEAN_HOUND: 'Tiroler Bracke',
      WACHTELHUND: 'Oysel-Hund (Wachtelhund)',
      WEIMARANER_LONGHAIRED: 'Weimaraner Langhaar Bracke',
      WEIMARANER_SHORTHAIRED: 'Weimaraner Kurzhaar',
      WELSH_CORGI_CARDIGAN: 'Welsh Corgi Cardigan',
      WELSH_CORGI_PEMBROKE: 'Welsh Corgi Pembroke',
      WELSH_SPRINGER_SPANIEL: 'Walisischer Springer Spaniel',
      WELSH_TERRIER: 'Welsh Terrier',
      WESTPHALIAN_DACHSBRACKE: 'Westfälische Dachsbracke',
      WEST_HIGHLAND_WHITE_TERRIER: 'West Highland White Terrier',
      WEST_SIBERIAN_LAIKA: 'Laika aus Westsibirien',
      WHIPPET: 'Whippet',
      WHITE_SWISS_SHEPHERD_DOG: 'Weißer Schweizer Schäferhund',
      WIREHAIRED_HUNGARIAN_POINTER: 'Ungarische Rauhhaarbracke',
      WIREHAIRED_POINTING_GRIFFON: 'Griffon mit Wollhaar',
      WIRE_FOX_TERRIER: 'Foxterrier Rauhaar',
      YORKSHIRE_TERRIER: 'Yorkshire Terrier',
      YUGOSLAVIAN_MOUNTAIN_HOUND: 'Montenegrinischer Gebirgslaufhund',
      YUGOSLAVIAN_SHEPHERD_DOG: 'Jugoslawischer Schäferhund',
      YUGOSLAVIAN_TRICOLOUR_HOUND: 'Montenegrinischer Gebirgslaufhund Dreifarbig'
    }
  },
  changePaymentMean: {
    ADD_BANK_ACCOUNT: 'Hinzufügen eines zu belastenden Kontos',
    ADD_BANK_CARD: 'Zur Zahlung per Kreditkarte wechseln',
    BANK_ACCOUNT_ERROR: 'Bei der Änderung Ihrer Bankverbindung ist ein Fehler aufgetreten. Bitte wenden Sie sich an unseren Kundenservice.',
    BANK_ACCOUNT_EXPLANATION: 'Wir weisen Sie darauf hin, dass Ihre Bankdaten von unseren Servern nicht gespeichert werden.',
    BANK_ACCOUNT_SUCCESS: 'Bankverbindung aktualisiert',
    BANK_CARD_ERRORED: 'Problem bei der Änderung der Zahlungsmethode. Bitte wenden Sie sich an unseren <1>Kundenservice</1>.',
    BANK_CARD_UPDATED: 'Kreditkarte aktualisiert',
    CAPTATION: 'Für die Aktualisierung Ihrer Bankinformationen autorisieren wir auf Ihrer Karte eine Gebühr von {{ amount }}.',
    CAPTATION_EXPLANATION1: 'Diese Summe wird nicht abgebucht. Die Transaktion wird automatisch storniert.',
    CAPTATION_EXPLANATION2: 'Dieses Verfahren ermöglicht uns, die Zahlungsstandards des Internets einzuhalten und die Sicherheit Ihrer Bankverbindung zu gewährleisten. Diese Informationen werden nicht über unsere Server geleitet.',
    CAPTATION_EXPLANATION3: 'ACHTUNG: Das Abbuchungsformular trägt den Namen "Hareau" und nicht "Weenect". Das ist normal, es ist der Name unseres Unternehmens. "Weenect" ist unser Markenname.',
    CAPTATION_EXPLANATION4: 'Ändern Sie Ihre Bankverbindung unter Beibehaltung desselben Abonnements.',
    CARD_AUTHORIZED: 'Die zulässigen Zahlungsmittel sind:',
    CHANGE_PAYMENT_MEAN: 'Meine Kreditkarteninformationen aktualisieren',
    CURRENT_CHOICE_CB: 'Sie haben sich für die Zahlung per Kreditkarte entschieden.',
    CURRENT_CHOICE_IBAN: 'Sie haben sich für die Zahlung per IBAN entschieden',
    CURRENT_MEAN: 'Aktuelle Zahlungsmethode',
    CURRENT_MEAN_PHRASE: 'Sie haben sich für die Zahlung per Kreditkarte entschieden',
    CURRENT_MEAN_PHRASE_GOCARDLESS: 'Sie haben sich für die Zahlung per Bankeinzug entschieden ',
    DEFAULT_MEAN: 'Art der Standardzahlung:',
    GO_TO_SEPA: 'Zum Lastschriftverfahren wechseln',
    MODIFY: 'Mein Zahlungsmittel ändern',
    TITLE: 'Zahlungsmittel',
    UPDATE_BANK_ACCOUNT: 'Meine IBAN aktualisieren',
    UPDATE_BANK_CARD: 'Meine Kreditkarteninformationen aktualisieren',
    USED_PAYMENT_EXPLANATION: 'Diese Zahlungsmethode wird verwendet, um Ihre Abonnements und Optionen zu bezahlen.'
  },
  commonActions: {
    ACTIVATE: 'Aktivieren',
    BACK: 'Zurück',
    CANCEL: 'Abbrechen',
    CAN_BE_CANCELED: 'Eine Kündigung ist jederzeit möglich.',
    CHANGE: 'Speichern',
    CONFIGURE: 'Konfigurieren',
    DEACTIVATE: '',
    GO_MAP: 'Zur Karte',
    MESSAGES: {
      UPDATE_ERROR_UNKNOWN: 'Es ist ein Problem aufgetreten.'
    },
    NEXT: 'Weiter',
    NO: 'Nein',
    OK: 'O. K.',
    REGISTER: 'Aktivieren',
    SAVE: 'Speichern',
    SELECT_ALL: 'Alles auswählen',
    SEND: 'Abschicken',
    SUBSCRIBE: 'Abonnieren',
    THEN: 'dann',
    UNSELECT_ALL: 'Alles abwählen',
    UPDATE: 'Speichern',
    VALIDATE: 'Bestätigen',
    YES: 'Ja'
  },
  creditCardAlert: {
    AUTO_RENEWAL_ADVICE: 'Wenn Sie Ihren Tracker weiterhin nutzen möchten, fügen Sie bitte jetzt ein neues Zahlungsmittel hinzu.',
    AUTO_RENEWAL_NOTICE: '<1>Achtung!</1> Ihr Abonnement läuft bald ab und Ihre Kreditkarte ist abgelaufen.'
  },
  emailPreferences: {
    EMAIL_LISTS: {
      COMPANY_NEWS: 'Neuigkeiten aus dem Unternehmen',
      NEW_FEATURES: 'Neue Funktionen',
      OFFERS: 'Werbeangebote',
      SERVICE_STATUS: 'Störungen des Dienstes und Updates',
      SURVEYS_AND_TESTS: 'Umfragen und Betatests',
      TUTORIALS: 'Tutorials'
    },
    EMAIL_LISTS_HEADER: 'Liste der E-Mails',
    EMAIL_LISTS_INFO: {
      SERVICE_STATUS: 'Für den reibungslosen Betrieb des Dienstes erforderlich.'
    },
    LANGUAGE_PREFERENCES_DESCRIPTION: 'Wählen Sie die Sprache, in der Sie die E-Mails erhalten möchten :',
    PREFERENCES_DECRIPTION: 'Wählen Sie die Art der E-Mails, die Sie erhalten möchten. Wir werden Ihnen nur diejenigen schicken, die Ihren Einstellungen entsprechen. '
  },
  error: {
    TITLE: 'Es ist ein Problem aufgetreten '
  },
  errors: {
    DEFAULT: 'Feld ungültig',
    FIELD_REQUIRED: 'Dies ist ein Pflichtfeld.',
    GENERAL: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.',
    IMEI: 'Geben Sie eine gültige IMEI-Nummer an.',
    MAIL_FORMAT: 'Die Form der E-Mail-Adresse ist ungültig.',
    MAX_LENGTH: 'Das Feld darf höchstens 15 Zeichen enthalten.',
    PASSWORD_FORMAT: 'Ihr Passwort muss mindestens eine Ziffer, einen Großbuchstaben, und einen Kleinbuchstaben enthalten.',
    PHONE_FORMAT: 'Die Telefonnummer muss die Landesvorwahl enthalten.',
    PROMO_CODE: 'Rabattcode nicht gültig'
  },
  forgotPassword: {
    FORGOT_PASSWORD_BUTTON: 'Link anfordern um ihr Passwort zurückzusetzen',
    FORGOT_PASSWORD_CANCEL: 'Zurück zur Anmeldung',
    FORGOT_PASSWORD_ERRORS: {
      UNKNOWN: 'Ein Fehler ist aufgetreten. Bitte nutzen Sie für die Kontaktaufnahme das vorgesehene Formular.'
    },
    FORGOT_PASSWORD_EXPIRED: 'Ihr Passwort-Zurücksetzen-Link ist abgelaufen.',
    FORGOT_PASSWORD_RETRY: 'Link anfordern, um Ihr Passwort zurückzusetzen.',
    FORGOT_PASSWORD_SUCCESS: 'Wenn ein Konto mit der E-Mail-Adresse {{mail}} verknüpft ist, erhalten Sie eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts.'
  },
  forms: {
    CITY: {
      LABEL: 'Stadt',
      PLACEHOLDER: 'Ihr Stadt'
    },
    CONFIRM_PASSWORD: {
      LABEL: 'Passwortbestätigung',
      PLACEHOLDER: 'Bestätigen'
    },
    COUNTRY: {
      LABEL: 'Land'
    },
    EMAIL: {
      LABEL: 'E-Mail-Adresse',
      PLACEHOLDER: 'ihre@email.com'
    },
    FIRST_NAME: {
      LABEL: 'Vorname',
      PLACEHOLDER: 'Ihr Vorname'
    },
    LANGUAGE: {
      LABEL: 'Sprache'
    },
    LAST_NAME: {
      LABEL: 'Nachname',
      PLACEHOLDER: 'Ihr Nachname'
    },
    NEW_PASSWORD: {
      LABEL: 'Neues Passwort',
      PLACEHOLDER: 'Ihr neues Passwort'
    },
    OLD_PASSWORD: {
      LABEL: 'Altes Passwort',
      PLACEHOLDER: 'Ihr altes Passwort'
    },
    PASSWORD: {
      LABEL: 'Passwort',
      PLACEHOLDER: 'Passwort',
      VALIDATION: 'Ihr Passwort muss aus mindestens 8 Zeichen bestehen',
      WARNING: 'Warnung: "qwertzuiopü" und "123456789" sind KEINE sicheren Passwörter.\nTesten Sie die Sicherheit Ihres Passworts hier: <1>https://howsecureismypassword.net</1>'
    },
    PHONE: {
      LABEL: 'Telefon',
      PLACEHOLDER: '+4900000000'
    },
    POSTAL_CODE: {
      LABEL: 'Postleitzahl',
      PLACEHOLDER: 'Ihre Postleitzahl',
      STREET_NUMBER: 'Straße und Hausnummer'
    },
    STREET: {
      LABEL: 'Anschrift',
      PLACEHOLDER: 'Straße und Hausnummer'
    }
  },
  geofences: {
    CREATE: {
      HEADLINE: 'Zone erstellen'
    },
    EDIT: {
      DELETE_GEOFENCE: 'Zone löschen',
      ENTRANCE_NOTIFICATION: 'In-Zone-Benachrichtigung',
      EXIT_NOTIFICATION: 'Aus-Zone-Benachrichtigung',
      GEOCODE_LABEL: 'Eine Adresse verwenden',
      GEOCODE_PLACEHOLDER: 'Geben Sie hier die Adresse ein.',
      LOCATION: 'Standort des Zentrums der Zone',
      NAME_INPUT: {
        LABEL: 'Name der Zone',
        PLACRHOLDER: 'Den Namen der Zone eingeben'
      },
      OUTDOORS: 'Außenzone (Park, Wald)',
      OUTDOORS_TOOLTIP: 'Da GPS-Tracker im Freien genauer sind, sind die Regeln für die Benachrichtigung einer Ein- oder Ausgangsmeldung in einer Innen- oder Außenzonen unterschiedlich.',
      POINT_ON_THE_MAP_TEXT: 'oder direkt auf der Karte auswählen',
      RADIUS: 'Größe der Zone (Radius)',
      TRACKER_LAST_POSITION_BUTTON: 'Den Standort des Trackers verwenden',
      UNKNOWN_ADDRESS: 'Unbekannte Adresse '
    }
  },
  header: {
    ALL_TRACKERS: 'Alle Tracker',
    AVAILABLE_PLURAL: 'verfügbar',
    NAV: {
      ACCOUNT: 'Konto',
      INSURANCE: 'Versicherung',
      LOGOUT: 'Abmelden',
      SUPPORT: 'Support',
      TRACKERS: 'Karte'
    },
    SEARCHBAR_RESULT: 'Tracker gefunden',
    SEARCHBAR_TITLE: 'Einen Tracker suchen:',
    TRACKERS: 'Tracker',
    WHITE_LABEL: {
      IN_PARTERNSHIP_WITH: 'In Partnerschaft mit'
    }
  },
  imeiInfo: {
    HINT: 'Sie finden die IMEI-Nummer auf der Rückseite Ihres Trackers.',
    IMAGE_ALT: 'Foto vom Tracker',
    TITLE: 'IMEI-Nummer'
  },
  login: {
    FORGOT_PASSWORD: 'Passwort vergessen?',
    LOGIN_ERRORS: {
      UNKNOWN: 'Die Anmeldung ist fehlgeschlagen.'
    },
    SIGN_IN_BUTTON: 'Anmelden',
    SIGN_IN_HEADLINE: 'Anmelden',
    SIGN_UP_BUTTON: 'Ein Konto erstellen',
    SIGN_UP_HEADLINE: 'Sie haben noch kein Konto?',
    TERMS_LINK: 'Nutzungsbedingungen',
    TERMS_LINK_URL: 'https://www.weenect.com/common/pdf/en/cgv-weenect.pdf'
  },
  manageSubscription: {
    CANCEL: {
      DESCRIBE_YOUR_PROBLEMS: 'Könnten Sie uns bitte den Grund für Ihre Kündigung verraten?',
      GIVE_US_DETAILS: 'Grund für Ihre Kündigung',
      REASONS: {
        AFTER_SALES_SERVICE: 'Schlechte Erfahrungen mit dem Kundenservice',
        BATTERY: 'Akkulaufzeit nicht ausreichend',
        LOST_BROKEN: 'Ich habe es verloren / kaputtgemacht',
        MOBILE_APPLICATION: 'Die App funktioniert nicht.',
        NOT_SATISFIED: 'Ich bin mit Weenect nicht zufrieden',
        NO_USE: 'Ich brauche es nicht mehr',
        TOO_EXPENSIVE: 'Das Abonnement ist zu teuer',
        TRACKER_FORMAT: 'Format des Trackers verbesserungsbedürftig (Größe und Gewicht)',
        WILL_REACTIVATE_LATER: 'Ich werde das Abo später reaktivieren.'
      },
      SAD_TO_SEE_YOU_LEAVE: 'Wir sind traurig, dass Sie uns verlassen!',
      WHY_DO_YOU_RESIGN: 'Warum möchten Sie Ihr Abo kündigen?'
    },
    CANCEL_EXPIRE: 'Die automatische Verlängerung ist aufgehoben. Das Abo läuft am {{date}} ab.',
    CANCEL_QUESTION: 'Möchten Sie das Abo nicht fortsetzen?',
    CANCEL_RENEWAL: 'Automatische Verlängerung aufheben',
    CANCEL_RENEWAL_BIS: 'Abo kündigen',
    CANT_RESIGN_UNDER_EXTENDED_NOTICE: 'Möchten Sie Ihr Abo kündigen oder nur die automatische Verlängerung Ihres Abos deaktivieren?\nRufen Sie uns an unter +33 (0) 1 87 66 98 71 (montags bis freitags von 9:30 bis 16:00 Uhr).',
    CANT_RESIGN_UNDER_NOTICE: 'Es ist nicht möglich, Ihr Abonnement innerhalb der letzten 30 Tage zu kündigen. Bitte wenden Sie sich an das Kundendienstteam.',
    CANT_RESIGN_UNTIL: 'Sie können Ihr Abo bis zum {{date}} nicht kündigen.',
    CANT_RESIGN_WHILE_COMMITED: 'Sie sind für 3 Monate gebunden, die Kündigung Ihres Abonnements wird erst zum Ende der laufenden Periode wirksam, d.h. am {{date}}',
    CURRENT_PLAN: 'Aktuelles Abo',
    HEADLINE: 'Abo verwalten',
    MESSAGES: {
      UPDATE_SUCCESS: 'Das Abo wurde aktualisiert.'
    },
    RENEW_SUBSCRIPTION: 'Abo verlängern',
    RESIGN_DATE: 'Die Kündigung Ihres Abonnements wird zum Ende des aktuellen Zeitraums wirksam, also zum {{date}}',
    RESUMPTION_DATE: 'Ein Wiederaufnahmedatum wählen',
    RETURN_TO_TRACKERS: 'Zurück zur Karte'
  },
  map: {
    NO_GPS_MARKER_TITLE: 'Kein GPS Signal',
    POSITION: 'Position'
  },
  mapControls: {
    MAP_TYPE: 'Kartentyp',
    REFRESH_POSITION: 'Position aktualisieren',
    SUPER_LIVE: 'Super Live',
    ZOOM_IN: 'Zoom +',
    ZOOM_OUT: 'Zoom -'
  },
  mapLayers: {
    CLASSIC_MAPS: 'Standardkarten',
    DONT_SUBSCRIBE: 'Weiterhin die Standardkarten benutzen',
    FOREST_PATH: 'Wege und Pfade',
    GOOGLE_STREET: 'Google Maps',
    GRAY: 'Schwarz-weiß',
    PLAN: 'Karte',
    PREMIUM_MAPS: 'Premium-Karten',
    PREMIUM_SUBSCRIBE: 'Sich für der Premium-Kartenoption abonnieren',
    PREMIUM_WARNING_MSG: 'Achtung! Sie haben für die Premium-Kartenoption noch kein Abonnement.\n\nDank dieser Option können Sie von besonders umfassenden und regelmäßig aktualisierten Karten profitieren und dabei die Nachfolgung Ihres Trackers optimieren. \u2028Mit den Varianten „HD-Satellitenkarte“, „Reliefkarte“ oder „Waldwege“ können Sie Ihren Tracker sofort mit einem Blick orten.\n\nAbonnement für nur {{mapOptionPrice}}/Monat!',
    RELIEF: 'Reliefkarte',
    SATELLITE: 'Satellit',
    STREET: 'Klassisch',
    UNLOCK_MAPS: 'Schalten Sie zusätzliche Karten frei, indem Sie sie anklicken!'
  },
  misc: {
    LANGUAGE: {
      DE: 'Deutsch',
      EN: 'Englisch',
      ES: 'Spanisch',
      FR: 'Französisch',
      IT: 'Italienisch',
      NL: 'Niederländisch'
    },
    NO_DATE: 'Kein bekannter Standort',
    ROTATE_DEVICE: 'Weenect ist für die Verwendung im Hochformat bestimmt. Bitte drehen Sie Ihr Gerät, um unsere Dienste optimal nutzen zu können. ',
    locale: 'de-DE'
  },
  onboarding: {
    ACTIVATING_HINT: 'Durchschnittliche Aktivierungsdauer: 5 Minuten',
    ACTIVATING_INDICATOR: 'Aktivierung läuft',
    GREET: {
      BUTTON: 'Ihr Tracker ist eingeschaltet.',
      COMMON_TEXT: 'Für einen optimalen Start empfehlen wir Ihnen, diesem Tutorial zu folgen. So wird Ihnen die Bedienung Ihres Tracker noch leichter fallen.\n\\In wenigen Schritten wird Ihr Gerät einsatzbereit sein. Bitte befolgen Sie die untenstehenden Anweisungen, um es einzuschalten:',
      MAIN_TITLE: 'Kontaktinformationen',
      RED_TEXT: 'Der Tracker muss sich im Freien befinden, um eine GPS-Position zu empfangen.'
    },
    MAP_INTERACTIONS: {
      COMMON_TEXT: {
        PET: 'Mit diesen Buttons können Sie Ihren Tracker klingeln (Button oben) oder vibrieren (Button unten) lassen. Diese Features ermöglichen es Ihnen, mit Ihrem Haustier das Rückrufkommando zu trainieren.',
        SILVER: 'Mit diesen Buttons  können Sie Ihren Tracker klingeln (Button oben) oder vibrieren (Button unten) lassen. '
      },
      TITLE: 'Buttons zur Interaktion mit dem Tracker'
    },
    MAP_INTERVAL: {
      COMMON_TEXT: 'Mit diesem Button können Sie auswählen, wie oft Sie eine aktuelle GPS-Position von Ihrem Tracker erhalten möchten. Bitte beachten Sie: Je kürzer das festgelegte Zeitintervall, desto geringer ist auch die Akkulaufzeit des Trackers.',
      TITLE: 'Button zur Festlegung des Zeitintervalls für den Positionsverlauf'
    },
    MAP_OPTIONS: {
      COMMON_TEXT: 'Mit diesen Buttons können Sie festlegen, welcher Kartentyp zur Darstellung verwendet werden soll. Zudem können Sie in die Karte hinein- oder herauszoomen.',
      TITLE: 'Button zur Festlegung der Kartenvariante'
    },
    MAP_UPDATE: {
      COMMON_TEXT: 'Mit diesem Button können Sie die GPS-Position Ihres Trackers aktualisieren (um eine neue Position zu erhalten, muss Ihr Tracker mit dem Server verbunden sein und ein gültiges GPS-Signal empfangen).',
      TITLE: 'Button zur Aktualisierung der GPS-Position'
    },
    MODAL: {
      BUTTON_HINT: 'Halten Sie die Taste 3 Sekunden lang gedrückt, bis der Tracker vibriert.',
      HEADLINE: 'Schalten Sie Ihren Tracker im Freien oder auf einer Fensterbank an.',
      IMAGE_ALT: 'Foto vom Tracker',
      MAP_HINT: 'Wenn der Tracker eingeschaltet ist, zeigt er im Durchschnitt nach 5 Minuten die Karte an.'
    },
    REMOTE_ASSISTANCE: {
      ASSISTANCE: {
        COMMON_TEXT: 'Reagiert niemand innerhalb dieses Zeitraums, so wird der Alarm an die Plattform weitergeleitet.'
      },
      EXPLANATION: 'Dort wird der Anruf entgegengenommen und sich um das Anliegen Ihres Angehörigen gekümmert. Je nach Situation kann der Mitarbeiter dort <1>Familienmitglieder kontaktieren oder den Rettungsdienst verständigen</1>.',
      PRICE: 'Die Option kostet 9,99 € pro Monat und kann jederzeit deaktiviert werden.',
      SOS: {
        COMMON_TEXT: 'Ihr Angehöriger drückt die SOS-Taste auf seinem Tracker.'
      },
      TIME: {
        COMMON_TEXT: 'Sie werden benachrichtigt und haben dann 30 Sekunden Zeit, um auf den Alarm zu reagieren.'
      },
      TITLE: 'Lernen Sie unsere neue Option kennen: die Fernunterstützung',
      USE_CASE: 'Ist diese Option aktiviert, kann der Träger des Trackers mit einer speziellen Plattform verbunden werden, über die täglich und rund um die Uhr Fernunterstützung angeboten wird.'
    },
    SIDE_PAGES: {
      COMMON_TEXT: {
        PET: 'Links finden Sie verschiedene Tabs zur Verwaltung und Verwendung Ihres Trackers. Im Tab Historie können Sie den Positionsverlauf und im Tab Aktivitätsverfolgung den Energieaufwand Ihres Haustiers einsehen. Sicherheitszonen können Sie im Tab Zonen definieren. Im Tab Einstellungen finden Sie Informationen zum Profil Ihres Haustiers und im Tab Abonnement können Sie dieses sowie Ihre Abo-Optionen mit wenigen Klicks verwalten.',
        SILVER: 'Links finden Sie verschiedene Tabs zur Verwaltung und Verwendung Ihres Trackers. Im Tab Historie können Sie den Positionsverlauf Ihres geliebten Menschen einsehen und im Tab Zonen können Sie Sicherheitszonen definieren.\n Die Telefonnummern, die im Falle eines abgesetzten SOS-Alarms angerufen werden sollen, sowie die Nachrichten, die beim Drücken der einzelnen seitlichen Knöpfe gesendet werden sollen, können Sie im Tab Einstellungen festlegen. Ihr Abonnement und Ihre Abo-Optionen können Sie im Tab Abonnement mit wenigen Klicks verwalten.'
      },
      TITLE: 'Zusätzliche Tabs'
    },
    SKIP: 'Tutorial überspringen',
    SUPERLIVE: {
      COMMON_TEXT: 'Mit diesem Button können Sie den "Superlive"-Modus aktivieren: So erhalten Sie fünf Minuten lang alle zehn Sekunden eine aktuelle GPS-Position.',
      TITLE: '"Superlive"-Button'
    },
    TUTORIAL_START: {
      BUTTON: 'Tutorial fortsetzen',
      COMMON_TEXT: 'Sehr gut, Ihr Tracker ist nun einsatzbereit. Lassen Sie uns mit einer kurzen Übersicht über Ihren persönlichen MyWeenect-Bereich fortfahren.'
    },
    WAITING_GPS_POSITION: 'GPS-Position wird gesucht.',
    WAITING_GPS_POSITION_HINT: 'Schalten Sie Ihren Tracker im Freien ein.'
  },
  optionCardExpired: {
    PROPOSAL: 'Bitte aktualisieren Sie Ihre Kreditkarteninformationen, um unseren Service weiterhin nutzen zu können.',
    UPDATE_CARD: 'Jetzt aktualisieren',
    WARNING: '<1>Achtung!</1> Leider können Sie diese Abonnenment-Option nicht wählen, da <1>Ihre Kreditkarte abgelaufen ist</1>.'
  },
  payment: {
    ADD_NEW_PAYMENT_REGULARISE: 'Bitte registrieren Sie eine neue Zahlungsmethode oder regeln Sie Ihre Situation.',
    EXPIRACY_CREDIT_CARD_TEXT: 'Bitte fügen Sie eine neue Zahlungsmethode hinzu, um Ihre Situation zu bereinigen.',
    NO_PAYMENT_MEAN_YET: 'Zurzeit keine Zahlungsmöglichkeiten',
    NO_PAYMENT_YET: 'Derzeit sind keine Zahlungen möglich.',
    UPDATE_PAYMENT_REGULARISE: 'Wenn Sie Ihren Tracker weiterhin verwenden möchten, aktualisieren Sie bitte jetzt Ihr Zahlungsmittel oder wählen Sie ein anderes.',
    WARNING_ERROR: 'Es scheint ein Problem mit Ihrem Zahlungsmittel zu geben.',
    WARNING_EXPIRACY_CREDIT_CARD: 'Attention, your credit card has expired.',
    WARNING_NOT_ENOUGH_FOUNDS: 'Bitte beachten Sie, dass auf Ihrem Konto nicht genügend Guthaben vorhanden ist.'
  },
  payments: {
    CARD: {
      ACCEPTED: '(EC-Karte, VISA, Mastercard, American Express)',
      DESCRIPTION: '30 Tage Zufriedenheitsgarantie oder Geld zurück',
      NAME: 'Kreditkarte'
    },
    STATUS: {
      CANCELED: 'Abgebrochen',
      ERROR: 'Fehlgeschlagen',
      NEW: 'In Bearbeitung',
      PAYMENT_ERROR: 'Zahlung fehlgeschlagen',
      PAYMENT_ERROR_EXPLAIN_TEXT: 'Bei Ihrer Zahlung ist ein Fehler aufgetreten. Bitte versuchen Sie es in Kürze erneut oder wenden Sie sich',
      PAYMENT_RETRY: 'Erneut versuchen',
      PAYMENT_SAV: 'Kundendienst.',
      PAYMENT_SUCCESS: 'Zahlung erfolgreich!',
      PAYMENT_SUCCESS_TEXT: 'Ihre Zahlung ist eingegangen. Sie können nun Ihre Weenect-Erfahrung starten!',
      PAYMENT_SUCCESS_TEXT_APP: 'Ihre Zahlung ist eingegangen.',
      PENDING: 'In Bearbeitung',
      REFUNDED: 'Erstattet',
      SUCCESS: 'Bestätigt'
    },
    TRANSFER: {
      DESCRIPTION: '15 zusätzliche Tage Bedenkzeit, somit eine 30-tägige Geld-zurück-Garantie bei Nichtzufriedenheit.',
      NAME: 'Bankeinzug'
    }
  },
  periods: {
    ALT: {
      MONTH: '1 Monat',
      YEAR: '1 Jahr',
      YEAR_plural: '{{count}} Jahre'
    },
    BASE: {
      MONTH: 'Monate',
      YEAR: 'Jahr'
    }
  },
  registerSummaryTable: {
    BILLED_MONTH: 'Die Zahlung erfolgt monatlich',
    BILLED_NOW: 'Sofortige Rechnungsstellung',
    BILLED_THREE_YEARS: 'Die Zahlung erfolgt alle drei Jahre',
    BILLED_TWO_YEARS: 'Wird alle zwei Jahre in Rechnung gestellt',
    BILLED_YEAR: 'Die Zahlung erfolgt jährlich',
    HINT_OPTIONS_PRICE: 'Die Optionen in Höhe von {{amount}} werden innerhalb von zwei Stunden getrennt vom Abo in Rechnung gestellt.',
    OPTION_UPGRADE: 'Die Option {{name}} hinzufügen – für nur {{price}} / {{period}}',
    PAY_HINT: 'Klicken Sie auf den Knopf „Zahlen und aktivieren“ und bezahlen Sie {{subscriptionPrice}} für das Abo.',
    SUBSCRIPTION_UPGRADE: 'Wechseln Sie zu einem 2-Jahresabo und sparen Sie jedes Jahr {{amount}}.',
    TOTAL: 'Gesamtpreis'
  },
  registerTracker: {
    BUY_LINK: 'Sie haben noch keinen Weenect-Tracker? <1>Hier können Sie einen kaufen.</1>',
    ERRORS: {
      UNKNOWN: 'Beim Aktivieren Ihres Trackers ist ein Problem aufgetreten, bitte versuchen Sie es erneut.'
    },
    HEADLINE: 'Einen neuen Tracker aktivieren',
    IMEI_INPUT: {
      LABEL: 'IMEI-Nummer',
      PLACEHOLDER: 'Sie finden die IMEI-Nummer auf der Rückseite Ihres Trackers.'
    },
    NAME_INPUT: {
      LABEL: 'Tracker-Name',
      PLACEHOLDER: 'Tracker-Name'
    }
  },
  remoteAssistance: {
    BASE: 'Support',
    FEATURES: 'Falls nach einem Drücken der SOS-Taste nicht binnen 30 Sekunden eine Antwort von Ihnen kommt, werden der SOS-Alarm und der Sprachanruf automatisch an unseren Fernsupport übermittelt, täglich und rund um die Uhr. Unsere Mitarbeiter sind befugt, Notdienste wie Polizei oder Rettungsdienst zu verständigen.',
    FORM: {
      ADDRESS_INPUT: {
        LABEL: 'Anschrift',
        PLACEHOLDER: 'Straße und Hausnummer'
      },
      BACK: 'Abbrechen',
      CITY_INPUT: {
        LABEL: 'Stadt',
        PLACEHOLDER: ''
      },
      EXTRA_PHONE: {
        LABEL: 'Zweittelefonnummer (optional)',
        PLACEHOLDER: ''
      },
      HEADLINE: 'Registrierung für den Support',
      NAME_INPUT: {
        LABEL: 'Vollständiger Name',
        PLACEHOLDER: 'Vorname NACHNAME'
      },
      PHONE: {
        LABEL: 'Telefon',
        PLACEHOLDER: ''
      },
      POSTAL_CODE_INPUT: {
        LABEL: 'Postleitzahl',
        PLACEHOLDER: ''
      },
      SAVE: 'Bestätigen',
      TERMS: 'Ich habe die <1>Nutzungsbedingungen</1> für den Dienst gelesen und akzeptiere sie.',
      TEXT: 'Geben Sie die Daten Ihres Angehörigen (der Person, die den Tracker trägt) ein. Anhand der Daten können unsere Mitarbeiter ihm/ihr bei einem SOS-Alarm zu Hilfe kommen.'
    },
    PENDING: {
      WAITING_FOR_CALL_TEST: 'Das Registrierungsformular ist bestätigt. Warte auf den Testanruf.\nSie müssen nun einen Testanruf tätigen, um die Option zu aktivieren. Entnehmen Sie die weitere Vorgehensweise bitte der E-Mail, die Ihnen gesendet wurde.',
      WAITING_FOR_RESIGN: 'Deaktivierung der aktuellen Option.\nWir haben Ihre Anfrage zur Deaktivierung der Option erhalten. Unsere Mitarbeiter kümmern sich jetzt um die Verwaltungsformalitäten. Die Deaktivierung erfolgt in Kürze.',
      WAITING_FOR_VALIDATION: 'Das Registrierungsformular wird jetzt geprüft. \nUnsere Mitarbeiter prüfen jetzt die Angaben, die Sie bei der Anmeldung für die Option gemacht haben. Sobald diese Prüfung abgeschlossen ist, kommen wir zum letzten Schritt vor der Aktivierung der Option: Es wird ein Testanruf getätigt, um zu prüfen, ob alles richtig funktioniert.'
    },
    USER_PHONE: {
      TEXT: 'Tragen Sie Ihre Angaben ein. Anhand der Angaben können unsere Mitarbeiter Sie bei einem SOS-Alarm verständigen.'
    }
  },
  signup: {
    ALREADY_MEMBER_TEXT: 'Haben Sie bereits ein Konto?',
    CREATE_ACCOUNT_HEADLINE: 'Ein Konto erstellen',
    LOGIN_TEXT: 'Mich einloggen',
    MARKETING_AGREEMENT_TEXT: 'Ich bin damit einverstanden, Angebote und Werbeaktionen von Weenect-Partnern zu erhalten.',
    RETRY: 'Erneut versuchen',
    SIGNUP_ERRORS: {
      EMAIL_ALREADY_EXISTS: 'Diese E-Mail-Adresse ist bereits mit einem Konto verknüpft.',
      POPIN_EMAIL_ALREADY_EXIST: 'Hoppla, diese E-Mail-Adresse scheint bereits in Gebrauch zu sein.',
      UNKNOWN: 'Die Anmeldung ist fehlgeschlagen, bitte versuchen Sie es erneut.'
    },
    SIGN_IN_TEXT: 'Anmelden',
    SUBMIT_BUTTON: 'Mein Konto erstellen',
    TERMS_AGREEMENT_TEXT: 'Ich akzeptiere <1>die Nutzungsbedingungen</1> für den Dienst.'
  },
  simInfo: {
    TITLE: 'SIM-Nummer'
  },
  subOptions: {
    CANCEL: {
      EXPIRATION_DATE: 'Diese Option wird bis zum {{Datum}} aktiv bleiben.',
      HEADLINE: 'Möchten Sie die Option {{option}} kündigen?'
    },
    MAP: {
      BASE: 'Multi-Karten Paket',
      FEATURES: 'Profitieren Sie von umfassenderem Kartenmaterial und regelmäßigen Karten-Aktualisierungen, um die Bewegungen Ihres Trackers noch genauer zu verfolgen. (HD-Satellitenkarte, Reliefkarte, Waldwege)',
      TITLE: 'Premium-Karten:'
    },
    PREMIUM: {
      BASE: 'Premium-Paket',
      BUTTON: {
        FREEMONTH: '1 Monat kostenlos testen!'
      },
      DEACTIVATE: 'Wenn Sie diese Option deaktivieren, verlieren Sie Ihre Premiumvorteile.',
      FEATURE: {
        CHAT: {
          BASE: 'Online-Chat',
          TEXT: 'Chatten Sie live von Ihrer App aus mit einem Mitarbeiter von Weenect. Der Service ist 7 Tage die Woche von 9 bis 18 Uhr verfügbar.',
          TEXT2: 'Nutzen Sie exklusive Vorteile (Online-Chat, SMS-Benachrichtigungen, HD-Karten) innerhalb der App.'
        },
        SMS: {
          TEXT: 'Sie erhalten alle Warnmeldungen per SMS (Guthaben für 40 SMS pro Monat) zusätzlich zu den Push- und E-Mail-Benachrichtigungen, die bereits im Abonnement enthalten sind.'
        }
      },
      FREEMONTH: {
        BASE: 'Erster Monat geschenkt!',
        BASE2: 'GESCHENKT'
      },
      SUBTITLE: 'Das Premium-Paket enthält die folgenden Features:',
      SUB_LEGAL: 'Diese Mitgliedschaft wird automatisch jeden Monat erneuert, über die Zahlungsmethode, die Sie beim Abschluss Ihres Abonnements gewählt haben. Sie können die Mitgliedschaft jederzeit über Ihren persönlichen Bereich kündigen.',
      TEXT_INFO_PRICE: 'Danach {{price}}/Monat nach dem ersten Monat.',
      TITLE_DISCOVER: 'Entdecken Sie Ihre exklusiven Vorteile : ',
      TITLE_POPUP: 'Werden Sie ein Premium-Mitglied!'
    },
    SMS_PACK: {
      ALT_FEATURES: 'Erhalten Sie alle Benachrichtigungen per SMS.',
      BASE: 'SMS-Paket',
      FEATURES: 'Erhalten Sie alle Benachrichtigungen per SMS (20 SMS pro Monat abrufbar). Bitte beachten Sie, dass Sie ohne diese Option Benachrichtigungen über Push-Nachrichten (App) und E-Mails erhalten.'
    },
    UNDEFINED: {
      PAYMENTS: 'Zahlung für die Option'
    },
    WARRANTY: {
      ALT_FEATURES: {
        one: 'Erhalten Sie kostenlos einen neuen Tracker, falls Ihr jetziges Gerät kaputtgeht, verloren oder gestohlen wird.',
        other: 'Zahlung mit Debitkarte, Kündigung jederzeit möglich',
        two: 'Ihr Tracker ersetzt in 48 Stunden im Falle von Verlust, Bruch oder Diebstahl, ohne Bedingungen.'
      },
      BASE: 'Multi-Risiko-Garantie',
      EXPLAINER: 'Die Standardgarantie deckt nur Probleme bei der Fertigung des Trackers ab.',
      FEATURES: 'Sie erhalten einen kostenlosen Ersatz-Tracker bei Defekt, Verlust oder Diebstahl Ihres derzeitigen Trackers; eine entsprechende Nachweispflicht ist nicht erforderlich. Die Standardgarantie deckt nur etwaige Fabrikationsfehler des Trackers ab.'
    }
  },
  subscription: {
    COMMON_TEXT: {
      MONTH: '/ Monat',
      OR: 'Oder '
    },
    FEATURES: {
      APP_ACCESS: 'Unbegrenzter Zugriff auf die App und den Ortungsdienst.',
      REFOUND: '15-tägige Testversion: Zufrieden oder Geld zurück.',
      SAV: 'Kundenservice an 6/7 Tagen verfügbar. \nGarantierte Antwort innerhalb von 24 Stunden.',
      WARRANTY: '24 Monate Herstellergarantie.'
    },
    FIRST_PAGE: {
      BUTTON: '',
      MODAL_GIFT_CARD: {
        BUTTON: '',
        INPUT_LABEL: '',
        TITLE: ''
      },
      MODAL_INFORMATION: {
        TEXT: '',
        TITLE: ''
      },
      SUBSCRIPTION_CGV: {
        FIRST_TEXT: '',
        INCLUDES: {
          FIRST: '',
          FIRST_BOLD: '',
          FOURTH: '',
          FOURTH_BOLD: '',
          SECOND: '',
          SECOND_BOLD: '',
          THIRD: '',
          THIRD_BOLD: ''
        },
        SECOND_TEXT: '',
        SECOND_TEXT_LINK: '',
        TITLE: ''
      },
      SUBSCRIPTION_CHOICE: {
        CHIP_ONE_MONTH: '',
        CHIP_ONE_YEAR: '',
        CHIP_THREE_YEAR: '',
        SUBSCRIPTION_TITLE_GIFT_CARD: '',
        SUBSCRIPTION_TITLE_MONTHLY: '',
        SUBSCRIPTION_TITLE_one: '',
        SUBSCRIPTION_TITLE_other: '',
        TITLE: ''
      }
    },
    FREE: '',
    HEADER: {
      STEP: '',
      TITLE: {
        FIRST_PAGE: '',
        SECOND_PAGE: '',
        THIRD_PAGE: ''
      }
    },
    INFO_CGU: 'Um die Abonnements zu nutzen, müssen Sie eine gültige Bankverbindung eingeben und akzeptieren.',
    INFO_CGU_BUTTON: 'die Allgemeinen Geschäftsbedingungen für die Nutzung des Dienstes.',
    INFO_OPTION: 'Die Zahlung erfolgt monatlich, eine Kündigung ist jederzeit möglich.',
    MONEY_BACK: 'Alle Abonnements werden sofort in Rechnung gestellt, können jedoch innerhalb von 15 Tagen gekündigt werden, wobei der gezahlte Betrag in voller Höhe zurückerstattet wird.',
    MONTH: '',
    PAYMENT_INFO: 'Geld-zurück-Garantie: 15 Tage, um Ihren Tracker auszuprobieren.',
    PAYMENT_SUBHEADLINE: 'Wählen Sie eine Zahlungsmethode, um Ihr Abonnement zu bezahlen und Ihren Tracker zu aktivieren.',
    PAYMENT_TITLE: 'Wählen Sie Ihre Zahlungsmethode aus.',
    PAYMENT_TITLE2: 'Registreer uw betaalmethode',
    PRICE_MONTH_SAVED: 'oder {{price}}/Monat',
    SECONDE_PAGE: {
      BUTTON: ''
    },
    SECOND_PAGE: {
      BASKET: {
        TEXT: {
          premium_pack: '',
          warranty: ''
        },
        TOTAL: ''
      },
      OPTION_CHOICE: {
        CHIP: '',
        TEXT: {
          premium_pack: '',
          warranty: ''
        },
        TITLE: {
          premium_pack: '',
          warranty: ''
        }
      },
      SUBTITLE: '',
      TITLE: ''
    },
    SUMMARY: 'Zusammenfassung',
    THIRD_PAGE: {
      BUTTON: '',
      MODAL: {
        TITLE: ''
      },
      PAYMENT_CHOICE: {
        BANK: '',
        CARD: '',
        TITLE: ''
      },
      SUBTITLE: ''
    }
  },
  subscriptionAlert: {
    ACTIVATE_AUTO_RENEWAL: 'Automatische Verlängerung wieder aktivieren',
    AUTO_RENEWAL_ADVICE: 'Wir empfehlen Ihnen, die automatische Verlängerung zu aktivieren, wenn Sie Ihr Abonnement verlängern möchten.',
    AUTO_RENEWAL_NOTICE: '<1>Achtung!</1> Ihr Abonnement für den {{trackerName}} Tracker läuft aus und die automatische Verlängerung ist deaktiviert.',
    CONTINUE_BUTTON: 'Fortfahren',
    MESSAGES: {
      UPDATE_ERROR_UNKNOWN: 'Es ist ein Problem aufgetreten.',
      UPDATE_SUCCESS: 'Erfolgreich aktualisiert'
    },
    REMIND_LATER: 'Mich später daran erinnern',
    SUPPORT_TEXT: 'Wenn Sie Fragen haben oder glauben, dass es sich hierbei um einen Fehler handelt, wenden Sie sich bitte an unseren <1>Kundenservice.</1>',
    TRIAL_CASE: {
      SUBSCRIBE_BUTTON: 'Ein Abonnement abschließen',
      TRIAL_IS_OVER: 'Ihre Testphase ist nun beendet',
      TRIAL_IS_OVER_ADVICE: 'Wir hoffen, dass Ihr Test erfolgreich war! Um das Produkt weiterhin nutzen zu können, müssen Sie nun ein Abonnement abschließen.'
    }
  },
  subscriptionOptionsSelection: {
    CANCEL_WARNING_MODAL_HEADLINE: 'Möchten Sie diese Option wirklich deaktivieren?',
    CANCEL_WARNING_SMS: 'Wenn Sie diese Option deaktivieren, erhalten Sie keine Alarmbenachrichtigungen per SMS.',
    CANCEL_WARNING_WARRANTY: 'Wenn Sie diese Option deaktivieren, verlieren Sie den zusätzlichen Schutz durch die Kombiversicherung.',
    EXPLANATION: 'Die Optionen sind standardmäßig aktiviert, aber Sie können diese durch einfaches Anklicken deaktivieren.',
    EXPLANATION2: 'And cancellation is possible at any time.',
    EXPLANATION3: 'Es werden keine Zahlungen durchgeführt.',
    HINT: 'Sie können jederzeit eine Option aktivieren oder deaktivieren.',
    MAIN_HEADLINE: 'Wählen Sie Ihre Optionen aus.',
    SUBMIT_BUTTON: 'Bestätigen'
  },
  subscriptionPayment: {
    ERRORS: {
      UNKNOWN: 'Während der Zahlung ist ein Problem aufgetreten, bitte versuchen Sie es erneut.'
    },
    GIFT_CARD: {
      EXPLANATION: 'Geben Sie den Code ein, der auf der Rückseite der in Ihrem Tracker enthaltenen Karte geschrieben ist.',
      I_HAVE_ONE: 'Ich habe eine Geschenkkarte',
      NEED_HELP: 'Brauchen Sie Hilfe beim Thema Geschenkkarten?',
      TITLE: 'Nutzen Sie Ihr Geschenk',
      USE: 'Benutzen'
    },
    HEADLINE: 'Zusammenfassung vor der Zahlung',
    PROMOCODE_TEXT: 'Ich habe einen Gutscheincode :',
    PROMO_CODE: 'Promo-Code',
    PROMO_CODE_GIFT_CARD: 'Geschenkkarte',
    SATISFIED_OR_REFUNDED: 'Unsere Garantie: 100% zufrieden oder Geld zurück',
    SATISFIED_OR_REFUNDED_DESCRIPTION: 'Profitieren Sie von einem 30-tägigen, risikofreien Testzeitraum – wenn Sie nicht zufrieden sind, erstatten wir Ihnen Ihr Geld zurück.',
    SELECT_PAYMENT_MEAN: 'Wählen Sie die von Ihnen gewünschte Zahlungsmethode vor dem Bezahlen und Aktivieren Ihres Abonnements aus.',
    SUBMIT_BUTTON: 'Abonnement bezahlen',
    SUBMIT_BUTTON2: 'Den Tracker aktivieren',
    SUMMARY: 'Ihre Auswahl im Überblick'
  },
  subscriptionProcess: {
    GIFT_CARD_EXPLANATION: 'Geben Sie Ihre bevorzugte Zahlungsmethode an, um Ihren Tracker kostenlos zu aktivieren.',
    GIFT_CARD_EXPLANATION2: 'Diese Information ist aus rechtlichen Gründen im Zusammenhang mit der Verwendung von SIM-Karten erforderlich.',
    RETURN_TO_APP: 'Zur Anwendung zurückkehren',
    RETURN_TO_MAP: 'Zurück zur Karte',
    RETURN_TO_TRACKERS: 'Zurück zur Karte',
    STEPS: [
      'Tracker',
      'Abo',
      'Optionen',
      'Zusammenfassung'
    ]
  },
  subscriptionSelection: {
    BEST_OFFER: 'BESTES ANGEBOT',
    CANCELABLE_AFTER_THREE_MONTHS: 'Kündigung nach Ablauf der ersten drei Monate jederzeit möglich',
    CANCEL_ANYTIME: 'Kündigung jederzeit möglich',
    COMMITMENT_TEXT: 'Jederzeit kündbar nach der Mindestbindungsdauer von 3 Monaten.',
    FEATURES: '',
    FEATURES_HEADLINE: 'Alle Abos enthalten:',
    HEADLINE: 'Wählen Sie Ihr Abo aus.',
    MIN_THREE_MONTHS: 'Drei Monate Mindestvertragslaufzeit',
    MONEY_BACK: 'Alle Abos werden sofort in Rechnung gestellt, können aber innerhalb von 30 Tagen widerrufen werden. In diesem Fall wird der gezahlte Betrag vollständig zurückerstattet.',
    PAYMENT_MEANS: 'Zahlung mittels Bankeinzug und Kreditkarte',
    REFUNDED_INFO: 'Wir erstatten das Abonnement bedingungslos zurück.',
    REFUNDED_TEXT: '30 Tage Probezeit: zufrieden oder Geld zurück.',
    SIM_CARD_WARNING: 'Eine SIM-Karte ist bereits fest in Ihren Tracker integriert. Dies erklärt, warum das Abschließen eines Abonnements notwendig ist (zur Deckung der anfallenden Nutzungsgebühren nämlich).',
    SUBMIT_BUTTON: 'Bestätigen',
    XMAS_OFFER: {
      BODY: 'Wir sind jedoch verpflichtet, Sie jetzt zu veranlassen, Ihr zukünftiges Abonnement zu wählen (aus Sicherheitsgründen, denn das Produkt ist mit einer SIM-Karte ausgestattet). Sie werden nicht vor Ablauf der 6 gratis Monate belastet und können dieses Abonnement jederzeit bis zu einem Monat vor dem Datum des Inkrafttretens kündigen.',
      TITLE: 'Ihr Tracker hat 6 Monate Abo vorausbezahlt.'
    },
    features: [
      '30 Tage Zufriedenheitsgarantie',
      'Uneingeschränkte GPS-Ortung',
      'Push-Benachrichtigungen (App) und E-Mail-Benachrichtigungen',
      '10 Minuten Sprachanruf-Guthaben pro Monat'
    ]
  },
  subscriptions: {
    BASE: 'Abo',
    CANCEL_MODAL: {
      HEADLINE: 'Möchten Sie die automatische Verlängerung Ihres Abos aufheben?',
      HINT: 'Ihr Abo bleibt bis zum {{date}} gültig, wird jedoch nicht automatisch verlängert. Nach diesem Datum werden Sie Ihren Tracker nicht mehr orten können.'
    },
    CHANGE_PLAN: {
      ADD_OPTIONS: 'Option hinzufügen',
      EXPLANATION: 'Der Abowechsel erfolgt am Ende der aktuellen Laufzeit.',
      HEADLINE: 'Abo wechseln?'
    },
    DETAILS: {
      ACTIVATE_BUTTON: {
        LABEL: 'Abo aktivieren'
      },
      ACTIVE_CASE: {
        MANAGE_BUTTON: 'Abo verwalten',
        RENEWAL_INFO: 'Ihr Abonnement wird automatisch am {{date}} zum Preis von {{price}} verlängert.'
      },
      CANCELED: {
        EXPIRED_MAIN: 'Ihr Abonnement ist abgelaufen. Reaktivieren Sie dieses, um Ihren Tracker orten zu können.',
        EXPIRED_SEC: 'Abgelaufen',
        UNCANCEL_BUTTON_LABEL: 'Automatische Verlängerung des Abos aktivieren',
        WILL_EXPIRE_DAYS: 'Läuft in {{count}} Tag ab',
        WILL_EXPIRE_DAYS_plural: 'Läuft in {{count}} Tagen ab',
        WILL_EXPIRE_MAIN: 'Die automatische Verlängerung des Abos ist aufgehoben. Es läuft am {{date}} ab.'
      },
      CANCEL_RESILLIATION: 'Abbrechen der Stornierungsanfrage',
      NEW_CASE: {
        INFO_TEXT: 'Wir sind auf ein Problem mit Ihrer Zahlung gestoßen. Bitte versuchen Sie es erneut oder ändern Sie Ihre Zahlungsdaten.',
        PAY_BUTTON: 'Zahlen und aktivieren',
        WAITING_TEXT: 'Wir bearbeiten gerade Ihre Zahlung für das Abonnement für diesen Tracker.'
      },
      NO_SUB: {
        INFO_TEXT: 'Aktivieren Sie das Abo, um mit der Benutzung Ihres Trackers zu beginnen.'
      },
      RESILLIATION_ASKED: 'Stornierung beantragt',
      SUBSCRIBE_BUTTON: {
        LABEL: 'Ein Abonnement abschließen'
      },
      SUPPORT_TEXT: 'Haben Sie Schwierigkeiten, Ihr Tracker zu aktivieren? Wenden Sie sich an unseren <1>Kundenservice</1>.',
      TRIAL_PERIOD_CASE: {
        END_INFO: 'Ihre Testphase endet am {{ date }}'
      }
    },
    OFFERS: {
      SAVING_PER_YEAR: 'Sparen Sie {{amount}} pro Jahr'
    },
    OFFERS_TEXT: {
      MOST_POPULAR: 'Das Beliebteste',
      ONE_MONTH: {
        INFO: 'Mindestverpflichtung von 3 Monaten',
        TITLE: 'Formel 1 Monat'
      },
      ONE_YEAR: {
        INFO: '15 Tage Probezeit zusätzlich: 30 Tage insgesamt.',
        TITLE: 'Formel 1 Jahr'
      },
      THREE_YEAR: {
        INFO: '1 Jahr zusätzliche Herstellergarantie: insgesamt 3 Jahre',
        INFO2: '3-year manufacturer\'s warranty (instead of two).',
        TITLE: '3-Jahres-Formel'
      }
    },
    OVERVIEW: {
      OPTIONS: 'Details zu den Optionen',
      REG_DETAILS: 'Tracker-Details',
      REPLACE_TRACKER_BUTTON: {
        LABEL: 'Tracker ersetzen'
      },
      SUB_DETAILS: 'Details zum Abo'
    },
    PLAN_NAMES: {
      FALLBACK: 'Abo',
      ONE_MONTH: 'Monatliches Abonnement',
      ONE_YEAR: 'Prepaid-Abonnement (1 Jahr)',
      THREE_YEARS: 'Prepaid-Abonnement (3 Jahre)',
      TRIAL_PERIOD: 'Probezeit',
      TWO_YEARS: '2-Jahresabo'
    },
    STATUS: {
      ACTIVE: 'Abonniert',
      CANCELED: 'Gekündigt',
      INACTIVE: 'Nicht Abonniert',
      NEW: 'Aktiv',
      PENDING: 'Warte'
    },
    TRACKER_DETAILS: {
      REGISTRATION_DATE: 'Aktivierungsdatum',
      WARRANTY: 'Standardgarantie'
    }
  },
  superLive: {
    ACTIVE_TEXT: 'Superlive ist aktiviert.',
    HEADLINE: 'Super-Live-Modus',
    MESSAGES: {
      SUCCESS_ACTIVATED: 'Der Superlive wurde aktiviert – 5 Minuten lang alle {{frequency}} Sekunden ein Standort.'
    },
    TIME: 'Ein Standort alle {{frequency}} Sekunden'
  },
  support: {
    FORM: {
      MESSAGE: {
        LABEL: 'Ihre Nachricht*',
        PLACEHOLDER: ''
      },
      PROBLEM: {
        AVAILABLE_OPTIONS: 'Verfügbare Optionen',
        LABEL: 'Art des Problems*'
      },
      SUBJECT: {
        LABEL: 'Betreff Ihrer Anfrage*',
        PLACEHOLDER: ''
      },
      TRACKER: {
        LABEL: 'Tracker, auf den sich Ihre Anfrage bezieht',
        PLACEHOLDER: 'Wählen Sie den Tracker aus'
      }
    },
    HEADLINE: 'Support',
    HELP_CENTER: 'Hilfe-Center',
    HELP_TEXT: 'Benötigen Sie weitere Hilfe? Besuchen Sie unser Kundencenter, um die Antworten auf Ihre Fragen zu finden. Sie können uns auch mithilfe des untenstehenden Formulars kontaktieren; unser Team antwortet an Werktagen innerhalb von 24 Stunden.',
    MAIN_TEXT: 'Unser Hilfezentrum enthält alle Artikel, die Ihre Fragen beantworten können.',
    MESSAGES: {
      SUBMIT_ERROR: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.',
      SUBMIT_SUCCESS: 'Nachricht erfolgreich versendet'
    },
    PROBLEM_TYPES: {
      CHARGE: 'Mein Tracker wird nicht normal aufgeladen.',
      DOWNLOAD_APP: 'Ich kann die App nicht herunterladen.',
      GPS_INVALID: 'Mein GPS-Signal wird als ungültig angezeigt.',
      GPS_PRECISION: 'Der GPS-Standort meines Trackers ist nicht korrekt.',
      LEDS_FIXED: 'Beide LED Lichter meines Trackers leuchten konstant.',
      NO_GEOFENCE_ALERTS: 'Ich erhalte Fehlalarme über das Betreten oder Verlassen von Zonen.',
      NO_NOTIFICATIONS: 'Ich erhalte keine Benachrichtigungen.',
      OTHER: 'Anderes',
      SIGN_IN: 'Ich kann mich nicht in meinem persönlichen Bereich anmelden.',
      SUBSCRIPTION: 'Ich habe ein Problem mit meinem Abonnement.',
      TRACKERS_PARAMS: 'Ich kann meinen Tracker nicht konfigurieren.',
      TRACKER_NOT_CONNECTED: 'Mein Tracker wird als nicht verbunden angezeigt.'
    },
    SUBMITTED_TICKET: 'Wir haben Ihre Nachricht erhalten, vielen Dank.\nSie haben eine Bestätigung per E-Mail geschickt bekommen (bitte sehen Sie auch in Ihrem Spam-Ordner nach).',
    SUB_TEXT: 'Sollten Ihnen diese Erklärungen nicht ausreichen, können Sie sich auch an einen Mitarbeiter unseres Serviceteams wenden, der Ihnen innerhalb von 24 Arbeitsstunden antworten wird.',
    TITLE: 'Benötigen Sie weitere Hilfe?',
    URGENT_NUMBER: 'Bei dringenden Anliegen erreichen Sie unseren Kundenservice unter \n+33 187 660 083 (montags bis freitags, 10–12 Uhr und 14–17 Uhr).'
  },
  trackerLeftPanel: {
    CALL: 'Anrufen',
    CTR_ONLY_SECONDS: 'Sie können noch {{SECONDS}} Sekunde(n) in diesem Monat telefonieren.',
    CTR_WITH_MINUTES: 'Sie können noch {{MINUTES}} Minute(n) und {{SECONDS}} Sekunde(n) in diesem Monat telefonieren.',
    FLASH: 'Taschenlampe',
    GOOD_GPS_ACCURACY: 'Hohe GPS-Genauigkeit',
    LAST_POSITION: 'Letzter bekannter Position:',
    MEDIUM_GPS_ACCURACY: 'Mittlere GPS-Genauigkeit',
    OFFLINE: 'Tracker-Verbindung getrennt',
    POOR_GPS_ACCURACY: 'Geringe GPS-Genauigkeit',
    RING: 'Klingeln lassen',
    SMS_AVAILABLE: 'SMS verfügbar : {{SMS_count}}',
    VIBRATE: 'Vibrieren lassen'
  },
  trackerSettings: {
    BUTTONS: {
      LABELS: {
        BUTTON: 'Schaltfläche'
      },
      PLACEHOLDER_BUTTON: 'Geben Sie die Nachricht ein, die über die Taste {{button_number}} versandt werden soll',
      TEXT: 'Konfigurieren Sie die Nachrichten, die jeder Taste zugewiesen werden. Diese Meldung erhalten Sie, wenn eine entsprechende Taste gedrückt wird.'
    },
    CONTACTS: {
      ADD_EMAIL_BUTTON: 'E-Mail-Adresse hinzufügen',
      ADD_PHONE_BUTTON: 'Telefonnummer hinzufügen',
      EMAILS_LABEL: 'E-Mail-Adressen für die E-Mail-Benachrichtungen',
      MAIL: {
        PLACEHOLDER: 'ihre@email.com'
      },
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Es ist ein Problem aufgetreten, bitte versuchen Sie es erneut.',
        UPDATE_SUCCESS: 'Kontakte aktualisiert'
      },
      NUMBERS_LABEL: 'Telefonnummern für die SMS-Benachrichtigungen',
      PHONE: {
        PLACEHOLDER: '+4900000000'
      }
    },
    DISPLAY: {
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Es ist ein Problem aufgetreten.',
        UPDATE_SUCCESS: 'Einstellungen aktualisiert'
      },
      MODAL: {
        HEADLINE: 'Icon auswählen',
        TEXT: 'Sie können das gewünschte Icon in der untenstehenden Liste auswählen. Es stellt Ihren Tracker auf der Karte dar.'
      },
      TRACKER_ICON: {
        EDIT_TEXT: 'Icon ändern',
        LABEL: 'Tracker-Symbol'
      },
      TRACKER_NAME: {
        LABEL: 'Tracker-Name',
        PLACEHOLDER: 'Name, der auf der Karte angezeigt wird'
      },
      TRACKER_TYPE: {
        CHANGE: 'Tracker-Typ ändern',
        MODIFIED_SUCCESS: 'Erfolgreich modifizierter Trackertyp'
      }
    },
    GPS_FREQUENCY: {
      BATTERY_USAGE: 'Durchschnittliche Akkulaufzeit:',
      BATTERY_USAGE_LEVELS: {
        HIGH: 'Hoch',
        LOW: 'Schwach',
        MEDIUM: 'Mittel'
      },
      GEOFENCE_NOTIFICATIONS: 'Durchschnittliche Reaktionszeit der Zonenalarme:',
      HINT: 'Eine schnellere Frequenz reduziert die Akkulaufzeit, verkürzt aber die Reaktionszeit der Alarme beim Betreten und Verlassen von Zonen. Umgekehrt schont eine langsamere Frequenz den Akku, verlängert aber die Reaktionszeit der Alarme beim Betreten und Verlassen von Zonen.',
      TEXT: 'Wählen Sie das Zeitintervall für GPS-Berichte aus.'
    },
    NOTIFICATION_SETTINGS: {
      APP: 'App',
      BATTERY: {
        HINT: 'Es wird eine Benachrichtigung an alle Kontakte gesendet, wenn der Tracker kaum mehr Batterie hat.',
        TITLE: 'Akku'
      },
      BUTTONS: {
        HINT: 'Nach dem Drücken einer Seitentaste wird eine Benachrichtigung an alle Kontakte gesendet. Die für diese Taste festgelegte Nachricht wird mit der Benachrichtigung gesendet.',
        TITLE: 'Seitentasten'
      },
      GEOFENCES: {
        HINT: 'Es wird eine Benachrichtigung an alle Kontakte gesendet, wenn der Tracker eine festgelegte Zone eintritt oder verlässt.',
        TITLE: 'Sicherheitszonen'
      },
      MAIL: 'E-Mail',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Es ist ein Problem aufgetreten, bitte versuchen Sie es erneut.',
        UPDATE_SUCCESS: 'Die Benachrichtigungseinstellungen wurden aktualisiert.'
      },
      SMS: 'SMS',
      SOS: {
        HINT: 'Nach dem Drücken der SOS-Taste wird eine Benachrichtigung an alle Kontakte gesendet.',
        TITLE: 'SOS Anruf'
      },
      WARNING: {
        SMS: {
          LINK: 'Klicken Sie hier, um sie zu aktivieren.',
          TEXT: 'Achtung! Wenn Sie Alarme per SMS erhalten möchten, müssen Sie die entsprechende Option aktivieren.'
        }
      }
    },
    SECTION_TITLES: {
      BUTTONS: 'Tasten',
      CONTACTS: 'Kontakte für die Benachrichtigungen',
      DISPLAY: 'Allgemein',
      GPS_FREQUENCY: 'Zeitintervall für GPS-Berichte',
      NOTIFICATION_SETTINGS: 'Art der Benachrichtigung für jeden Alarm',
      SOS: 'SOS-Nummer',
      TRAINING: 'Anrufen'
    },
    TRAINING_SOS: {
      DIRECT_CALL: 'Diese Nummer bei SOS-Warnmeldungen direkt anrufen.',
      INPUT_PLACEHOLDER: '+4900000000',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Es ist ein Problem aufgetreten, bitte versuchen Sie es erneut.',
        UPDATE_SUCCESS: 'Telefonnummer aktualisiert'
      },
      SOS_TEXT: 'Telefonnummer, die im Falle eines SOS-Alarms angerufen wird',
      TRAINING_TEXT: 'Geben Sie die Telefonnummer an, die der Tracker standardmäßig anruft, wenn die Telefonfunktion in der App benutzt wird. Diese Nummer kann bei jeder Anrufanfrage in der App bearbeitet werden.'
    }
  },
  trackers: {
    ADD_TRACKER_BUTTON: {
      LABEL: 'Tracker hinzufügen'
    },
    EXPIRATION: {
      EXPIRED: 'Das Abo für {{tracker}} ist abgelaufen.',
      WILL_EXPIRE: 'Das Abo für {{tracker}} läuft in {{days}} Tagen ab.'
    },
    LINKS: {
      ACTIVITY: 'Aktivitätsverfolgung',
      GEOFENCES: 'Zonen',
      HISTORY: 'Verlauf',
      SETTINGS: 'Einstellungen',
      SUBSCRIPTION: 'Abo'
    }
  },
  trackersPositionRefresh: {
    MESSAGES: {
      NOT_REFRESHED: 'Ihr Tracker konnte die neuen GPS-Standorte nicht abrufen.',
      REFRESHING: 'Bitte warten, eine Update-Anfrage wird gerade bearbeitet.',
      SUCCESS: 'Die GPS-Standorte der Tracker wurden aktualisiert.'
    }
  },
  transferPage: {
    ADD_TRACKER: 'Ich möchte einen neuen Tracker zu meinem Konto hinzufügen',
    BACK_TO_TRACKERS: 'Zurück zur Karte',
    FIRST_TITLE: 'Was genau möchten Sie tun?',
    FROM_HEADLINE: 'Tracker, der ersetzt werden soll:',
    HEADLINE: 'Einen Tracker ersetzen',
    IMEI_LABEL: 'IMEI-Nummer',
    INFO: 'Das aktuelle Abo, der GPS-Standortverlauf und die Einstellungen werden nach dem Umtausch auf den neuen Tracker übertragen.',
    MESSAGES: {
      ERROR_UNKNOWN: 'Es ist ein Problem aufgetreten, bitte versuchen Sie es erneut.',
      SUCCESS: 'Neuer Tracker zugewiesen',
      WRONG_TYPE: 'Der Tracker mit dieser IMEI-Nummer entspricht nicht dem Typ Ihres alten Trackers. Bitte wenden Sie sich an unseren Kundenservice.'
    },
    NAME_LABEL: 'Tracker-Name',
    PURCHASE: 'Sie haben noch keinen Weenect-Tracker? <1>Hier können Sie einen kaufen.</1>',
    REPLACE_BUTTON: {
      LABEL: 'Ersetzen'
    },
    REPLACE_TRACKER: 'Ich möchte einen vorhandenen Tracker ersetzen',
    TO_HEADLINE: 'Neuer Tracker:'
  },
  webappDisconnection: {
    BUG: 'Nicht verbunden',
    MANUAL: 'Sie haben Ihren Tracker ausgeschaltet. Drücken Sie die ON-Taste, um das Gerät wieder einzuschalten.',
    NO_BATTERY: 'Ihr Tracker hat sich ausgeschaltet, weil der Akku leer war.',
    NO_SUB: 'Sie haben kein Abonnement für diesen Tracker. Bitte holen Sie sich eines, indem Sie auf die Website gehen'
  },
  wifiZones: {
    AIRPLANE_TEXT: 'Achtung: Im Flugzeugmodus können Sie Ihren Tracker, solange er sich in der Energiesparzonen befindet, nicht mehr klingeln oder vibrieren lassen  oder seine Position manuell aktualisieren.',
    DEEP_SLEEP_TITLE: 'Flugzeugmodus',
    DISPLAY_BUTTON: 'Zone auf der Karte anzeigen',
    DISPLAY_TITLE: 'Benachrichtigungen und Anzeige',
    IN_AIRPLANE: '- in Flugzeugmodus',
    IN_OUT_TEXT: 'Ein- und Ausgänge der Zone:',
    LAST_POS_IN_WIFI: 'In der Energiesparzonen seit {{date}} ',
    MAIL_NOTIF: 'Mail Benachrichtigungen',
    NOTIF_TEXT: 'Erhalten Sie eine Benachrichtigung über jeden Ein- und Ausstieg aus dem vordefinierten Bereich',
    POPIN: {
      AIRPLANE: {
        BUTTON_VALIDATE: 'Den Flugzeugmodus verlassen',
        CONTENT: 'Sie können nicht mehr mit Ihrem Tracker kommunizieren, solange er sich in der Energiesparzonen befindet. Wenn Sie die volle Funktionalität wiederherstellen möchten, <strong>schalten Sie den Flugzeugmodus aus und nehmen Sie den Tracker aus der Zone heraus. </strong>',
        TITLE: 'Ihr Tracker befindet sich im Flugzeugmodus in der Energiesparzone {{wifiName}}'
      },
      BUTTON_CLOSE: 'Später',
      BUTTON_VALIDATE: 'Zone deaktivieren',
      CONTENT: 'In dieser Zone ist die automatische Übertragung der GPS-Positionen Ihres Trackers deaktiviert, Sie können ihn jedoch klingeln lassen, um ihn zu finden.<strong> Sie können auch die Energiesparzonen deaktivieren</strong>.',
      TITLE: 'Ihr Tracker befindet sich in der Energiesparzone {{wifiName}}.'
    },
    PUSH_NOTIF: 'Push-Benachrichtigungen',
    SMS_NOTIF: 'SMS-Benachrichtigungen',
    TITLE: 'Energiesparzonen',
    ZONE_ACTIVATION: 'Zonenaktivierung'
  }
}
