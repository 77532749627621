import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import ProfileBanner from "../../molecules/ProfileBanner/ProfileBanner";
import HomeDailyGoal from "../../molecules/HomeDailyGoal/HomeDailyGoal";
import Warning from "../../atoms/Warning/Warning";
import HomeActivityDistribution from "../../molecules/HomeActivityDistribution/HomeActivityDistribution";
// import Insurance from "../../molecules/Insurance/Insurance";
import SeeMore from "../../molecules/SeeMore/SeeMore";
import { AppRoutes } from "../../../routes/AppRoutes";
import DataComparator from "../../molecules/DataComparator/DataComparator";
import { getComparator } from "../../../helpers/getComparator";
import { getDayOfTheWeek } from "../../../helpers/getDayOfTheWeek";
import { get2DaysAgo } from "../../../helpers/timeConverter";
import { useGetSummaryKeysQuery } from "../../../rtk/summary/summary";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";
import { useEffect, useState } from "react";
import { getUrlParameters } from "../../../helpers/getUrlParameters";
import { enumMetrics } from "../../../types/enums/enumMetrics";
import { nullableConverterNumber } from "../../../helpers/nullableConverter";
import { Link } from "react-router-dom";

interface Props {
  displayWarning: boolean;
  country: string;
}

const Home = ({ displayWarning, country }: Props) => {
  const { t } = useTranslation();
  const trackerId = useAppSelector((state: RootState) => state.auth.trackerId);
  const petName = useAppSelector((state: RootState) => state.tracker.petName);
  const tz = useAppSelector((state: RootState) => state.tracker.timezone);
  const petPictureUrl = useAppSelector(
    (state: RootState) => state.tracker.petPictureUrl
  );
  const [step, setStep] = useState<{ first: number; last: number }>({
    first: 0,
    last: 0,
  });
  const [distance, setDistance] = useState<{ first: number; last: number }>({
    first: 0,
    last: 0,
  });
  const [urlParams, setUrlParams] = useState<string>(
    getUrlParameters({ metric: enumMetrics.PAS, tz })
  );
  const { data: summary, isLoading: isLoadingSummary } = useGetSummaryKeysQuery(
    { trackerId: trackerId || "", parameters: urlParams },
    { skip: !trackerId }
  );

  useEffect(() => {
    if (summary && summary.metric === enumMetrics.PAS) {
      setStep({
        first: nullableConverterNumber(summary.first.value),
        last: nullableConverterNumber(summary.last.value),
      });
      setUrlParams(getUrlParameters({ metric: enumMetrics.KM, tz }));
    }
    if (summary && summary.metric === enumMetrics.KM) {
      setDistance({
        first: nullableConverterNumber(summary.first.value),
        last: nullableConverterNumber(summary.last.value),
      });
    }
  }, [summary, tz]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ProfileBanner
          title={t("activityTracking:ONBOARDING.SECONDE_STEP.PROFILE.TITLE", {
            petName,
          })}
          subTitle={t("activityTracking:HOME.PROFILE.SUBTITLE")}
          isLoading={!summary && isLoadingSummary}
          src={petPictureUrl}
        />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "20px" }}>
        <SeeMore
          title={t("activityTracking:HOME.FIRST_SECTION.TITLE")}
          textArrow={t("activityTracking:HOME.MODIFY")}
          link={AppRoutes.GOAL}
        />
      </Grid>
      <Grid item xs={12}>
        <Link to={AppRoutes.REPORT}>
          <HomeDailyGoal />
        </Link>
      </Grid>
      {displayWarning && (
        <Grid item xs={12}>
          <Warning>{t("activityTracking:HOME.ALERT")}</Warning>
        </Grid>
      )}
      <Grid item xs={12}>
        <SeeMore
          title={t("activityTracking:HOME.SECOND_SECTION.TITLE")}
          textArrow={t("activityTracking:HOME.SEE_MORE")}
          subtitle={t("activityTracking:HOME.SECOND_SECTION.SUBTITLE")}
          link={AppRoutes.DISTRIBUTION}
        />
      </Grid>
      <Grid item xs={12}>
        <Link to={AppRoutes.DISTRIBUTION}>
          <HomeActivityDistribution />
        </Link>
      </Grid>
      <Grid item xs={12}>
        <SeeMore
          title={t("activityTracking:HOME.THIRD_SECTION.TITLE")}
          textArrow={t("activityTracking:HOME.SEE_MORE")}
          link={AppRoutes.REPORT}
        />
      </Grid>
      <Grid item xs={12}>
        <Link to={AppRoutes.REPORT}>
          <DataComparator
            title={t(
              "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.TITLE_STEP"
            )}
            text={t(
              "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.TEXT_STEP_DAILY",
              {
                petName,
                comparator: getComparator(step.last, step.first, t),
              }
            )}
            valueOne={step.last}
            legendOne={t(
              "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.LEGEND.STEP_YESTERDAY"
            )}
            valueTwo={step.first}
            legendTwo={`${t("activityTracking:STEP")} ${getDayOfTheWeek(
              get2DaysAgo(),
              t,
              true
            )}`}
            isLoading={!summary && isLoadingSummary}
          />
        </Link>
      </Grid>
      <Grid item xs={12} sx={{ display: "none" }}>
        <Link to={AppRoutes.REPORT}>
          <DataComparator
            title={t(
              "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.TITLE_KM_TRAVELED"
            )}
            text={t(
              "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.TEXT_KM_DAILY",
              {
                petName,
                comparator: getComparator(distance.first, distance.last, t),
              }
            )}
            valueOne={distance.first}
            legendOne={t(
              "activityTracking:ACTIVITY_REPORT.DATA_COMPARATOR.LEGEND.KM_YESTERDAY"
            )}
            valueTwo={distance.last}
            legendTwo={`${t("activityTracking:KILOMETERS")} ${getDayOfTheWeek(
              get2DaysAgo(),
              t,
              true
            )}`}
            isLoading={!summary && isLoadingSummary}
          />
        </Link>
      </Grid>
      {/* {country === "FR" && (
        <Grid item xs={12}>
          <Insurance
            title={t("activityTracking:HOME.INSURANCE.TITLE", {
              petName: petName,
            })}
            text={t("activityTracking:HOME.INSURANCE.TEXT")}
            textButton={t("activityTracking:HOME.INSURANCE.BUTTON")}
          />
        </Grid>
      )} */}
    </Grid>
  );
};

export default Home;
