import { useCallback, useEffect, useState } from "react";
import { Avatar, Grid } from "@mui/material";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";

import trophyPath from "../../../assets/Trophy.svg";
import Title from "../../atoms/Title/Title";
import Warning from "../../atoms/Warning/Warning";
import DailyGoal from "../../molecules/DailyGoal/DailyGoal";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";
import { useUpdateActivityMutation } from "../../../rtk/activity/activity";
import { secondesToMinutes } from "../../../helpers/timeConverter";
import { nullableConverterNumber } from "../../../helpers/nullableConverter";
import { AppRoutes } from "../../../routes/AppRoutes";
import { useNavigate } from "react-router-dom";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { useGetSummaryWeekActivityQuery } from "../../../rtk/summary/summary";

const AimedGoal = () => {
  const { t } = useTranslation();
  const trackerId = useAppSelector((state: RootState) => state.auth.trackerId);
  const { data: summaryData, isLoading } = useGetSummaryWeekActivityQuery(
    { trackerId: trackerId || "" },
    { skip: !trackerId }
  );
  const [goal, setGoal] = useState<number>(0);
  const [updateActivity] = useUpdateActivityMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (summaryData) {
      setGoal(
        secondesToMinutes(nullableConverterNumber(summaryData.today.goal))
      );
    }
  }, [summaryData]);

  const handlerInput = useCallback(async () => {
    try {
      const response = await updateActivity({
        trackerId: trackerId ?? "",
        body: { goal },
      }).unwrap();
      console.log("Response :", response);
      navigate(AppRoutes.BASE_ROUTE);
    } catch (error) {
      console.log("Error :", error);
    }
  }, [goal, trackerId, updateActivity, navigate]);

  if (!summaryData && isLoading) return <SpinnerLoader />;

  return (
    <Grid container spacing={2} className={"AimedGoal"}>
      <Grid item xs={12}>
        <Avatar className="avatar">
          <ReactSVG src={trophyPath} />
        </Avatar>
      </Grid>
      <Grid item xs={12}>
        <Title variant="h3">{t("activityTracking:AIMED_GOAL.TITLE")}</Title>
      </Grid>
      {goal < 60 && !isLoading && (
        <Grid item xs={12}>
          <Warning>{t("activityTracking:AIMED_GOAL.ALERT")}</Warning>
        </Grid>
      )}
      <Grid item xs={12}>
        <DailyGoal
          title={t("activityTracking:DAILY_GOAL.SECOND_TITLE")}
          goal={goal}
          setGoal={setGoal}
          isLoading={!summaryData && isLoading}
        />
      </Grid>
      <Grid item xs={12} className="alignCenter">
        <StyledButton onClick={handlerInput}>
          {t("activityTracking:ONBOARDING.SECONDE_STEP.BUTTON")}
        </StyledButton>
      </Grid>
    </Grid>
  );
};

export default AimedGoal;
